import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { toast } from 'react-toastify'
import { firebaseAuth } from 'config'
import { FARMER_PATHS, PUBLIC_PATHS } from 'routing'
import { useAuthStatus } from 'hooks'
const Login = () => {
  const navigate = useNavigate()
  const { loggedIn } = useAuthStatus()

  if (loggedIn) {
    navigate(FARMER_PATHS.dashboard)
  }

  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })
  const [loading, setLoading] = useState(false)

  const { email, password } = formData

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)

      const userCredential = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      )

      if (userCredential.user) {
        setLoading(false)
        navigate(FARMER_PATHS.dashboard)
      }
    } catch (error) {
      setLoading(false)
      toast.error('Bad user credentials')
    }
  }

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-lg-500px bg-body rounded box-shadow-1 p-10 p-lg-15 mx-auto vertically-centered-form'>
              <form className='form w-100' onSubmit={onSubmit}>
                <div className='text-center mb-10'>
                  <h1 className='text-dark mb-3'>Sign In</h1>
                  <div className='text-gray-400 fw-bold fs-4'>
                    New Here?
                    <Link
                      to='/register'
                      className='link-success fw-bolder ms-3'
                    >
                      Create an Account
                    </Link>
                  </div>
                </div>
                <div className='fv-row mb-10'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    Email
                  </label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    id='email'
                    value={email}
                    onChange={onChange}
                    autoComplete='off'
                  />
                </div>
                <div>
                  <div className='mb-10 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1'>
                      <div className='d-flex flex-stack mb-2'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                          Password
                        </label>
                        <Link
                          to={PUBLIC_PATHS.forgotPassword}
                          className='link-success fs-6 fw-bolder'
                        >
                          Forgot Password ?
                        </Link>
                      </div>
                      <div className='position-relative mb-3'>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          type='password'
                          placeholder='*********'
                          id='password'
                          autoComplete='off'
                          value={password}
                          onChange={onChange}
                        />
                        <span
                          onClick={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                          className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                          data-kt-password-meter-control='visibility'
                        >
                          <i className='bi bi-eye-slash fs-2' />
                          <i className='bi bi-eye fs-2 d-none' />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className={`btn btn-lg btn-success w-100 mb-5 ${
                      loading && 'disabled'
                    }`}
                  >
                    <span className='indicator-label fw-700 text-uppercase'>
                      {loading ? 'Please Wait...' : 'Continue'}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
