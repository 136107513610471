import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import {
    collection,
    getDocs,
    addDoc,
    query,
    where,
    onSnapshot
} from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { Empty, Spin, Form, notification, Modal } from 'antd'
import 'antd/dist/antd.css'
import { db } from 'config'
import { FarmerTrackersTable, Footer, Nav, Spinner } from 'components'
import { FARMER_PATHS } from 'routing'

const Trackers = () => {
    // STATE
    const [isFormLoading, setIsFormLoading] = useState(false)
    const [isClosable, setIsClosable] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [trackers, setTrackers] = useState([])
    const [trackerTypes, setTrackerTypes] = useState([])
    const [reportYear, setReportYear] = useState('2022')
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        effectiveHectares: '',
        financialYear: '',
        trackerType: '',
        createdAt: new Date().toISOString(),
        locked: false
    })

    // const trackerTypes = [
    //     {
    //         name: 'Tomatoes',
    //         type: 'crop'
    //     },
    //     {
    //         name: 'Maize',
    //         type: 'crop'
    //     },
    //     {
    //         name: 'Potatoes',
    //         type: 'crop'
    //     },
    //     {
    //         name: 'Piggery',
    //         type: 'livestock'
    //     },
    //     {
    //         name: 'Chicken',
    //         type: 'livestock'
    //     },
    //     {
    //         name: 'Cattle',
    //         type: 'livestock'
    //     },
    //     {
    //         name: 'Onions',
    //         type: 'crop'
    //     },
    //     {
    //         name: 'Beans',
    //         type: 'crop'
    //     }
    // ]

    let years = []

    const currentYear = new Date().getFullYear()

    for (let i = 0; i <= 15; i++) {
        years.push(currentYear + i)
    }

    const { effectiveHectares, financialYear, trackerType, createdAt } =
        formData

    // OTHER
    const navigate = useNavigate()
    const auth = getAuth()
    const trackersCollectionRef = collection(db, 'trackers')
    const trackerTypesRef = collection(db, 'tracker_types')
    const user = auth.currentUser

    const closeModals = () => {
        setIsModalVisible(false)
    }

    const closeReportModal = () => {
        setReportModal(false)
    }

    const openModals = () => {
        setIsModalVisible(true)
    }

    // REQUESTS
    useEffect(() => {
        fetchTrackers()
        fetchTrackerTypes()
    }, [])

    const fetchTrackers = async () => {
        const q = query(trackersCollectionRef, where('userId', '==', user.uid))

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const trackersData = []
            querySnapshot.forEach((doc) => {
                trackersData.push({
                    id: doc.id,
                    data: doc.data()
                })
            })
            setTrackers(trackersData)
            setLoading(false)
        })
    }

    const fetchTrackerTypes = async () => {
        const q = query(trackerTypesRef)

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = []
            querySnapshot.forEach((doc) => {
                data.push(doc.data())
            })
            setTrackerTypes(data)
            setLoading(false)
        })
    }

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const onReportYearChange = (e) => {
        setReportYear(e.target.value)
    }

    const addTrackers = async (e) => {
        e.preventDefault()

        try {
            setIsFormLoading(true)
            const formDataCopy = { ...formData }

            formDataCopy.userId = user.uid
            formDataCopy.effectiveHectares = Number(
                formDataCopy.effectiveHectares
            )
            formDataCopy.trackerType = trackerTypes[formDataCopy.trackerType]

            await addDoc(trackersCollectionRef, formDataCopy)

            setIsFormLoading(false)
            setIsModalVisible(false)

            fetchTrackers()
        } catch (error) {
            setIsFormLoading(false)

            toast.error('Something went wrong')
        }
    }

    if (loading) {
        return <Spinner />
    }

    let toRender
    let toRenderEmpty
    if (trackers.length > 0 && loading === false) {
        toRender = (
            <>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                        <tr className='fw-bolder text-dark'>
                            <th className='min-w-120px'>Effective Hectares</th>
                            <th className='min-w-150px'>Financial Year</th>
                            <th className='min-w-140px'>Tracker Name</th>
                            <th className='min-w-140px'>Tracker Type</th>
                            <th className='min-w-140px'>Date Created</th>
                            <th className='min-w-140px'>Locked</th>
                            <th className='min-w-100px text-end'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!trackers &&
                            trackers.map((item) => {
                                return (
                                    <FarmerTrackersTable
                                        key={item.id}
                                        id={item.id}
                                        effectiveHectares={
                                            item.data.effectiveHectares
                                        }
                                        financialYear={item.data.financialYear}
                                        trackerName={item.data.trackerType.name}
                                        trackerType={item.data.trackerType.type}
                                        createdAt={item.data.createdAt}
                                        locked={item.data.locked}
                                    />
                                )
                            })}
                    </tbody>
                </table>
            </>
        )
    } else if (loading === false) {
        toRenderEmpty = (
            <>
                <Empty
                    className='mt-4'
                    imageStyle={{
                        height: 120
                    }}
                    description={
                        <h6 className='text-green-2 fw-600 fs-14px'>
                            Sorry there are no trackers found at the moment
                        </h6>
                    }
                />
            </>
        )
    }

    return (
        <>
            <div className='d-flex flex-column flex-root'>
                <div className='page d-flex flex-row flex-column-fluid'>
                    <div
                        className='wrapper d-flex flex-column flex-row-fluid'
                        id='kt_wrapper'
                    >
                        <Nav />

                        <div
                            id='kt_content_container'
                            className='d-flex flex-column-fluid align-items-start container-xxl'
                        >
                            <div
                                className='content flex-row-fluid'
                                id='kt_content'
                            >
                                <div className='row gy-5 g-xl-8'>
                                    <div className='col-12'>
                                        <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                            <div className='card-header'>
                                                <h3 className='card-title align-items-start flex-column'>
                                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                                        Trackers
                                                    </span>
                                                </h3>
                                                <div
                                                    className='card-toolbar'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    data-bs-trigger='hover'
                                                    title='Click to add a user'
                                                >
                                                    <a
                                                        href='#'
                                                        className='btn btn-sm btn-success btn-active-success'
                                                        onClick={openModals}
                                                    >
                                                        Add
                                                    </a>

                                                    <a
                                                        href='#'
                                                        className='btn btn-sm btn-primary btn-active-primary ms-3'
                                                        onClick={() =>
                                                            setReportModal(true)
                                                        }
                                                    >
                                                        Generate Report
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='card-body py-3'>
                                                <div className='table-responsive'>
                                                    {toRender}
                                                    <center>
                                                        {toRenderEmpty}
                                                        <Spin
                                                            spinning={loading}
                                                        />
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal
                            style={{ marginTop: '-60px' }}
                            visible={isModalVisible}
                            footer={false}
                            closable={isClosable}
                            onCancel={closeModals}
                            width={800}
                        >
                            <div className='modal-dialog modal-lg'>
                                <div className='modal-content border-0 rounded-0'>
                                    <div className='modal-header border-0 rounded-0 bg-light'>
                                        <h5
                                            className='modal-title text-dark fw-bold fs-3'
                                            id='add-productLabel'
                                        >
                                            Add Tracker
                                        </h5>
                                    </div>
                                    <div className='modal-body border-0 rounded-0'>
                                        <Spin spinning={isFormLoading}>
                                            <form
                                                className='form w-100'
                                                onSubmit={addTrackers}
                                            >
                                                <div className='row mb-7'>
                                                    <div className='col-md-6'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Effective Hectares /
                                                            Number of Items
                                                        </label>
                                                        <input
                                                            className='form-control form-control-lg form-control-solid'
                                                            type='number'
                                                            id='effectiveHectares'
                                                            value={
                                                                effectiveHectares
                                                            }
                                                            onChange={onChange}
                                                            autoComplete='off'
                                                        />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Financial Year
                                                        </label>
                                                        <select
                                                            className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                            id='financialYear'
                                                            value={
                                                                financialYear
                                                            }
                                                            onChange={onChange}
                                                        >
                                                            <option
                                                                disabled
                                                                value=''
                                                            >
                                                                Select Option
                                                            </option>
                                                            {years.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                item
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='fv-row mb-7'>
                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                        Tracker
                                                    </label>
                                                    <select
                                                        className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                        id='trackerType'
                                                        value={trackerType}
                                                        onChange={onChange}
                                                    >
                                                        <option
                                                            disabled
                                                            value=''
                                                        >
                                                            Select Option
                                                        </option>
                                                        {trackerTypes?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            index
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </option>
                                                                )
                                                            }
                                                        )}
                                                    </select>
                                                </div>

                                                <div className='text-center'>
                                                    <button
                                                        type='submit'
                                                        id='kt_sign_in_submit'
                                                        className='btn btn-lg btn-success w-100 mb-5'
                                                    >
                                                        <span className='indicator-label fw-700 text-uppercase'>
                                                            Continue
                                                        </span>
                                                    </button>
                                                </div>
                                            </form>
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            style={{ marginTop: '-60px' }}
                            visible={reportModal}
                            footer={false}
                            closable={isClosable}
                            onCancel={closeReportModal}
                            width={400}
                        >
                            <div className='modal-dialog modal-lg'>
                                <div className='modal-content border-0 rounded-0'>
                                    <div className='modal-header border-0 rounded-0 bg-light'>
                                        <h5
                                            className='modal-title text-dark fw-bold fs-3'
                                            id='add-productLabel'
                                        >
                                            Generate Report
                                        </h5>
                                    </div>
                                    <div className='modal-body border-0 rounded-0'>
                                        <Spin spinning={isFormLoading}>
                                            <form className='form w-100'>
                                                <div className='row mb-7'>
                                                    <div className='col-12'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Year
                                                        </label>
                                                        <select
                                                            className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                            value={reportYear}
                                                            required
                                                            onChange={
                                                                onReportYearChange
                                                            }
                                                        >
                                                            {years.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                item
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='text-center'>
                                                    <Link
                                                        to={`${FARMER_PATHS.annualProfitAndLoss}/${reportYear}`}
                                                        className='btn btn-lg btn-success w-100 mb-5'
                                                    >
                                                        <span className='indicator-label fw-700 text-uppercase'>
                                                            Continue
                                                        </span>
                                                    </Link>
                                                </div>
                                            </form>
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Trackers
