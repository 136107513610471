import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
    addDoc,
    collection,
    doc,
    getDoc,
    onSnapshot,
    query,
    updateDoc,
    where
} from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { DatePicker, Spin } from 'antd'
import 'antd/dist/antd.css'
import { _months, _trackerTypes, _expenseCategories } from 'data'
import { db } from 'config'
import { Footer, Nav } from 'components'
import { useSelector } from 'react-redux'
import { selectUser } from 'features/userSlice'
import { selectTrackerEntries } from 'features/trackerEntriesSlice'
import { getAuth } from 'firebase/auth'
import formatDate from 'utils/formatDate'
import { FARMER_PATHS } from 'routing'

const FarmerDailyTransactions = () => {
    // STATE
    const date = new Date()
    const currMonth = _months[date.getMonth()]
    const auth = getAuth()
    const trackersCollectionRef = collection(db, 'trackers')
    const [loading, setLoading] = useState(true)
    const [isFormLoading, setIsFormLoading] = useState(false)
    const [trackers, setTrackers] = useState([])
    const [tracker, setTracker] = useState([])
    const [trackerData, setTrackerData] = useState([])
    const [dateSelected, setDateSelected] = useState('')
    const [calculateIncomeAmount, setCalculateIncomeAmount] = useState({
        volume: '',
        unitValue: ''
    })
    const [otherIncomeAmount, setOtherIncomeAmount] = useState()
    const [entryType, setEntryType] = useState('Expense')
    let expectedIncome =
        calculateIncomeAmount.volume * calculateIncomeAmount.unitValue
    const expenseDataInitialState = {
        createdAt: new Date().toISOString(),
        amount: '',
        loanId: null,
        month: '',
        name: '',
        type: 'Expense',
        trackerId: '',
        actual: true
    }
    const [expenseFormData, setExpenseFormData] = useState(
        expenseDataInitialState
    )
    const { amount, month, name } = expenseFormData
    const [incomeFormData, setIncomeFormData] = useState({
        createdAt: new Date().toISOString(),
        amount: '',
        month: '',
        type: 'Income',
        incomeType: 'otherIncome',
        trackerId: '',
        actual: true
    })
    const params = useParams()

    const user = useSelector(selectUser)

    // Selected Date
    const formattedDate =
        dateSelected !== '' ? new Date(dateSelected) : new Date()

    const selectedMonth = _months[formattedDate.getMonth()]

    const monthToSubmit = dateSelected !== '' ? selectedMonth : currMonth

    const trackerEntries = useSelector(selectTrackerEntries)
    const filteredEntry = trackerEntries?.filter(
        (t) =>
            t.data.trackerId === '' &&
            t.data.month === '' &&
            t.data.name === '' &&
            t.data.actual === true &&
            t.data.type === 'Expense'
    )

    const currentYear = new Date().getFullYear()
    let years = []
    for (let i = 0; i <= 15; i++) {
        years.push(currentYear + i)
    }

    // OTHER
    const trackerEntriesCollectionRef = collection(db, 'tracker_entries')
    const trackerEntriesSubRef = collection(db, 'tracker_entries_sub')

    const onIncomeChange = (e) => {
        setIncomeFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const onChangeCalculateAmount = (e) => {
        const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/
        if (e.target.value === '' || re.test(e.target.value)) {
            setCalculateIncomeAmount((prevState) => ({
                ...prevState,
                [e.target.id]: e.target.value
            }))
        }
    }

    const onOtherIcomeAmountChange = (e) => {
        setOtherIncomeAmount(e.target.value)
    }

    const onOtherEntryTypeChange = (e) => {
        setEntryType(e.target.value)
    }

    const onTrackerChange = (e) => {
        setTracker(e.target.value)
    }

    const onDateChange = (e) => {
        setDateSelected(e.target.value)
    }

    const onExpenseChange = (e) => {
        setExpenseFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const addExpense = async (e) => {
        e.preventDefault()

        try {
            setIsFormLoading(true)
            const expenseFormDataCopy = { ...expenseFormData }

            expenseFormDataCopy.trackerId = tracker
            expenseFormDataCopy.amount = Number(expenseFormDataCopy.amount)
            expenseFormDataCopy.userId = user.uid
            expenseFormDataCopy.month = monthToSubmit

            const filteredEntry = trackerEntries?.filter(
                (t) =>
                    t.data.trackerId === tracker &&
                    t.data.month === monthToSubmit &&
                    t.data.name === expenseFormDataCopy.name &&
                    t.data.actual === true &&
                    t.data.type === 'Expense'
            )

            if (filteredEntry.length > 0) {
                const entryDoc = doc(db, 'tracker_entries', filteredEntry[0].id)
                const entryDocData = await getDoc(entryDoc)
                const updates = {
                    amount:
                        entryDocData.data().amount + expenseFormDataCopy.amount
                }
                await updateDoc(entryDoc, updates)

                await addDoc(trackerEntriesSubRef, {
                    amount: expenseFormDataCopy.amount,
                    createdAt: new Date().toISOString(),
                    entry_id: entryDoc.id,
                    entry_type: 'Expense',
                    month: expenseFormDataCopy.month,
                    name: expenseFormDataCopy.name,
                    trackerId: expenseFormDataCopy.trackerId,
                    userId: expenseFormDataCopy.userId
                })
            } else {
                let trackerEntry = await addDoc(
                    trackerEntriesCollectionRef,
                    expenseFormDataCopy
                )

                await addDoc(trackerEntriesSubRef, {
                    amount: expenseFormDataCopy.amount,
                    createdAt: new Date().toISOString(),
                    entry_id: trackerEntry.id,
                    entry_type: 'Expense',
                    month: expenseFormDataCopy.month,
                    name: expenseFormDataCopy.name,
                    trackerId: expenseFormDataCopy.trackerId,
                    userId: expenseFormDataCopy.userId
                })
            }

            setExpenseFormData(expenseDataInitialState)
            setIsFormLoading(false)
            toast.success('Successfuly added an expense')
        } catch (error) {
            setIsFormLoading(false)
            toast.error('Something went wrong')
        }
    }

    const addIncome = async (e) => {
        e.preventDefault()

        try {
            setIsFormLoading(true)
            const incomeFormDataCopy = { ...incomeFormData }

            incomeFormDataCopy.trackerId = tracker
            incomeFormDataCopy.amount =
                incomeFormDataCopy.incomeType === 'tracker'
                    ? Number(expectedIncome)
                    : Number(otherIncomeAmount)
            incomeFormDataCopy.userId = user.uid
            incomeFormDataCopy.month = monthToSubmit

            const filteredEntry = trackerEntries?.filter(
                (t) =>
                    t.data.trackerId === tracker &&
                    t.data.month === monthToSubmit &&
                    t.data.incomeType === incomeFormDataCopy.incomeType &&
                    t.data.actual === true &&
                    t.data.type === 'Income'
            )

            if (filteredEntry.length > 0) {
                const entryDoc = doc(db, 'tracker_entries', filteredEntry[0].id)
                const entryDocData = await getDoc(entryDoc)
                const updates = {
                    amount:
                        entryDocData.data().amount + incomeFormDataCopy.amount
                }
                await updateDoc(entryDoc, updates)

                if (incomeFormDataCopy.incomeType === 'tracker') {
                    await addDoc(trackerEntriesSubRef, {
                        amount: incomeFormDataCopy.amount,
                        createdAt: new Date().toISOString(),
                        entry_id: entryDoc.id,
                        entry_type: 'Income',
                        month: incomeFormDataCopy.month,
                        incomeType: incomeFormDataCopy.incomeType,
                        trackerId: incomeFormDataCopy.trackerId,
                        userId: incomeFormDataCopy.userId,
                        volume_amount: Number(calculateIncomeAmount.volume),
                        unit_cost: Number(calculateIncomeAmount.unitValue)
                    })
                } else {
                    await addDoc(trackerEntriesSubRef, {
                        amount: incomeFormDataCopy.amount,
                        createdAt: new Date().toISOString(),
                        entry_id: entryDoc.id,
                        entry_type: 'Income',
                        month: incomeFormDataCopy.month,
                        incomeType: incomeFormDataCopy.incomeType,
                        trackerId: incomeFormDataCopy.trackerId,
                        userId: incomeFormDataCopy.userId
                    })
                }
            } else {
                let trackerEntry = await addDoc(
                    trackerEntriesCollectionRef,
                    incomeFormDataCopy
                )

                if (incomeFormDataCopy.incomeType === 'tracker') {
                    await addDoc(trackerEntriesSubRef, {
                        amount: incomeFormDataCopy.amount,
                        createdAt: new Date().toISOString(),
                        entry_id: trackerEntry.id,
                        entry_type: 'Income',
                        month: incomeFormDataCopy.month,
                        incomeType: incomeFormDataCopy.incomeType,
                        trackerId: incomeFormDataCopy.trackerId,
                        userId: incomeFormDataCopy.userId,
                        volume_amount: Number(calculateIncomeAmount.volume),
                        unit_cost: Number(calculateIncomeAmount.unitValue)
                    })
                } else {
                    await addDoc(trackerEntriesSubRef, {
                        amount: incomeFormDataCopy.amount,
                        createdAt: new Date().toISOString(),
                        entry_id: trackerEntry.id,
                        entry_type: 'Income',
                        month: incomeFormDataCopy.month,
                        incomeType: incomeFormDataCopy.incomeType,
                        trackerId: incomeFormDataCopy.trackerId,
                        userId: incomeFormDataCopy.userId
                    })
                }
            }

            setIsFormLoading(false)
            toast.success('Successfuly added an income')
        } catch (error) {
            setIsFormLoading(false)

            toast.error('Something went wrong')
        }
    }

    // Trackers

    useEffect(() => {
        const fetchTrackers = async () => {
            const q = query(
                trackersCollectionRef,
                where('userId', '==', user.uid)
            )

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const trackersData = []
                querySnapshot.forEach((doc) => {
                    trackersData.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })
                setTrackers(trackersData)
                setLoading(false)
            })
        }

        fetchTrackers()
    }, [])

    useEffect(() => {
        const getTracker = async () => {
            const trackerRef = doc(db, 'trackers', tracker)
            const trackerData = await getDoc(trackerRef)

            if (trackerData.exists()) {
                setTrackerData({ id: trackerData.id, data: trackerData.data() })
            } else {
                console.log('No such document!')
            }
        }

        getTracker()
    }, [tracker])

    return (
        <>
            <div className='d-flex flex-column flex-root'>
                <div className='page d-flex flex-row flex-column-fluid'>
                    <div
                        className='wrapper d-flex flex-column flex-row-fluid'
                        id='kt_wrapper'
                    >
                        <Nav />

                        <div
                            id='kt_content_container'
                            className='d-flex flex-column-fluid align-items-start container-xxl'
                        >
                            <div
                                className='content flex-row-fluid'
                                id='kt_content'
                            >
                                <div className='row gy-5 g-xl-8'>
                                    <div className='col-md-6 mx-auto'>
                                        <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                            <div className='card-header d-flex align-items-center justify-content-between'>
                                                <p className='fw-bolder fs-3 mb-0'>
                                                    Daily Transactions
                                                </p>
                                                <Link
                                                    to={`${FARMER_PATHS.trackerEntries}${trackerData?.data?.trackerType?.name}/${tracker}`}
                                                    className={`btn btn-primary float-end ${
                                                        tracker.length <= 0 &&
                                                        'disabled'
                                                    }`}
                                                >
                                                    {' '}
                                                    Go to Tracker
                                                </Link>
                                            </div>
                                            <div className='card-body py-3'>
                                                <Spin spinning={isFormLoading}>
                                                    <form
                                                        className='form w-100'
                                                        onSubmit={
                                                            entryType ===
                                                            'Income'
                                                                ? addIncome
                                                                : addExpense
                                                        }
                                                    >
                                                        <div className='row mb-7'>
                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6 fw-bolder text-dark'>
                                                                    Tracker
                                                                </label>
                                                                <select
                                                                    className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                                    id='tracker'
                                                                    required
                                                                    value={
                                                                        tracker
                                                                    }
                                                                    onChange={
                                                                        onTrackerChange
                                                                    }
                                                                >
                                                                    <option
                                                                        disabled
                                                                        value=''
                                                                    >
                                                                        Select
                                                                        Tracker
                                                                    </option>
                                                                    {trackers?.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    value={
                                                                                        item.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item
                                                                                            .data
                                                                                            .trackerType
                                                                                            .name
                                                                                    }
                                                                                </option>
                                                                            )
                                                                        }
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6 fw-bolder text-dark'>
                                                                    Entry Type
                                                                </label>
                                                                <select
                                                                    className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                                    id='entryType'
                                                                    value={
                                                                        entryType
                                                                    }
                                                                    onChange={
                                                                        onOtherEntryTypeChange
                                                                    }
                                                                >
                                                                    <option
                                                                        disabled
                                                                        value=''
                                                                    >
                                                                        Select
                                                                        Type
                                                                    </option>
                                                                    <option value='Expense'>
                                                                        Expense
                                                                    </option>
                                                                    <option value='Income'>
                                                                        Income
                                                                    </option>
                                                                </select>
                                                            </div>

                                                            <div className='col-12 mt-7'>
                                                                <label className='form-label fs-6 fw-bolder text-dark'>
                                                                    Date
                                                                </label>
                                                                <input
                                                                    value={
                                                                        dateSelected
                                                                    }
                                                                    onChange={
                                                                        onDateChange
                                                                    }
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    type='date'
                                                                    placeholder='Date'
                                                                />
                                                                {/* <DatePicker
                                                                    className='form-control form-control-lg form-control-solid focus rounded-2'
                                                                    placeholder='End Date'
                                                                /> */}
                                                            </div>
                                                        </div>
                                                        {entryType ===
                                                        'Expense' ? (
                                                            <>
                                                                <div className='row mb-7'>
                                                                    <div className='col-12'>
                                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                                            Expense
                                                                            Type
                                                                        </label>
                                                                        <select
                                                                            className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                                            id='name'
                                                                            value={
                                                                                name
                                                                            }
                                                                            onChange={
                                                                                onExpenseChange
                                                                            }
                                                                        >
                                                                            <option
                                                                                disabled
                                                                                value=''
                                                                            >
                                                                                Select
                                                                                Type
                                                                            </option>
                                                                            {_expenseCategories.map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) => {
                                                                                    return (
                                                                                        <option
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            value={
                                                                                                item
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                item
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='fv-row mb-7'>
                                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                                        Amount
                                                                    </label>
                                                                    <input
                                                                        className='form-control form-control-lg form-control-solid'
                                                                        type='text'
                                                                        id='amount'
                                                                        value={
                                                                            amount
                                                                        }
                                                                        onChange={
                                                                            onExpenseChange
                                                                        }
                                                                        placeholder='e.g. $15.50'
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='row mb-7'>
                                                                    <div className='col-12'>
                                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                                            Income
                                                                            Type
                                                                        </label>
                                                                        <select
                                                                            className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                                            id='incomeType'
                                                                            value={
                                                                                incomeFormData.incomeType
                                                                            }
                                                                            onChange={
                                                                                onIncomeChange
                                                                            }
                                                                        >
                                                                            <option
                                                                                disabled
                                                                                value=''
                                                                            >
                                                                                Select
                                                                                Type
                                                                            </option>
                                                                            <option value='tracker'>
                                                                                From
                                                                                Tracker
                                                                            </option>
                                                                            <option value='otherIncome'>
                                                                                From
                                                                                Other
                                                                                Income
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {incomeFormData.incomeType ===
                                                                'tracker' ? (
                                                                    <>
                                                                        <div className='row mb-7'>
                                                                            <div className='col-md-6'>
                                                                                <label className='form-label fs-6 fw-bolder text-dark'>
                                                                                    Volume/Amount/Weight
                                                                                </label>
                                                                                <input
                                                                                    className='form-control form-control-lg form-control-solid'
                                                                                    type='text'
                                                                                    id='volume'
                                                                                    value={
                                                                                        calculateIncomeAmount.volume
                                                                                    }
                                                                                    onChange={
                                                                                        onChangeCalculateAmount
                                                                                    }
                                                                                    placeholder='e.g. 100 kgs'
                                                                                />
                                                                            </div>

                                                                            <div className='col-md-6'>
                                                                                <label className='form-label fs-6 fw-bolder text-dark'>
                                                                                    Unit
                                                                                    Value
                                                                                </label>
                                                                                <input
                                                                                    className='form-control form-control-lg form-control-solid'
                                                                                    type='text'
                                                                                    id='unitValue'
                                                                                    value={
                                                                                        calculateIncomeAmount.unitValue
                                                                                    }
                                                                                    onChange={
                                                                                        onChangeCalculateAmount
                                                                                    }
                                                                                    placeholder='e.g. $15.50/kg'
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='fv-row mb-7'>
                                                                            <label className='form-label fs-6 fw-bolder text-dark'>
                                                                                Expected
                                                                                Income
                                                                            </label>
                                                                            <input
                                                                                className='form-control form-control-lg form-control-solid'
                                                                                type='text'
                                                                                id='amount'
                                                                                value={
                                                                                    expectedIncome
                                                                                }
                                                                                onChange={
                                                                                    onIncomeChange
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className='fv-row mb-7'>
                                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                                            Amount
                                                                        </label>
                                                                        <input
                                                                            className='form-control form-control-lg form-control-solid'
                                                                            type='text'
                                                                            id='amount'
                                                                            value={
                                                                                otherIncomeAmount
                                                                            }
                                                                            onChange={
                                                                                onOtherIcomeAmountChange
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}

                                                        <div className='text-center'>
                                                            <button
                                                                type='submit'
                                                                id='kt_sign_in_submit'
                                                                className='btn btn-lg btn-success w-100 mb-5'
                                                            >
                                                                <span className='indicator-label fw-700 text-uppercase'>
                                                                    Continue
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </Spin>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FarmerDailyTransactions
