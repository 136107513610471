const Spinner = () => {
  return (
    <>
      <div className='text-center' style={{ marginTop: '20%' }}>
        <div
          className='spinner-border text-success'
          style={{ width: '2rem', height: '2rem' }}
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    </>
  )
}

export default Spinner
