import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { Empty, Spin, Modal } from 'antd'
import 'antd/dist/antd.css'
import { _months, _trackerTypes, _expenseCategories } from 'data'
import { db } from 'config'
import { Footer, Nav } from 'components'
import { firebaseAuth } from 'config'
import IncomeEntryCell from '../../components/farmer/IncomeEntryCell'
import IncomeActualsEntryCell from '../../components/farmer/IncomeActualsEntryCell'
import IncomeMonthlyTotal from '../../components/farmer/IncomeMontlyTotal'
import { getTrackerExpenseCategories } from '../../utils/getTrackerExpenseCategories'
import ExpenseEntryCell from '../../components/farmer/ExpenseEntryCell'
import ExpensesMonthlyTotals from '../../components/farmer/ExpensesMonthlyTotals'
import NetMonthlyTotalCell from '../../components/farmer/NetMonthlyTotalCell'
import IncomeActualsMontlyTotal from 'components/farmer/IncomeActualsMontlyTotal'
import ExpenseActualsEntryCell from 'components/farmer/ExpenseActualsEntryCell'
import ExpensesActualsMonthlyTotals from 'components/farmer/ExpensesActualsMonthlyTotals'
import NetMonthlyActualsTotalCell from 'components/farmer/NetMonthlyActualsTotalCell'
import { useSelector } from 'react-redux'
import { selectUser } from 'features/userSlice'
import { selectTrackerEntries } from 'features/trackerEntriesSlice'

const TrackerEntries = () => {
    // STATE
    const [isFormLoading, setIsFormLoading] = useState(false)
    const [isClosable, setIsClosable] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [overwritingModal, setOverwritingModal] = useState(false)
    const [isIncomeModalVisible, setIsIncomeModalVisible] = useState(false)
    const [tracker, setTracker] = useState({})
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [trackerExpenseCategories, setTrackerExpenseCategories] = useState([])
    const [calculateIncomeAmount, setCalculateIncomeAmount] = useState({
        volume: '',
        unitValue: ''
    })
    const [otherIncomeAmount, setOtherIncomeAmount] = useState()
    let expectedIncome = Number(
        calculateIncomeAmount.volume * calculateIncomeAmount.unitValue
    ).toFixed(2)
    const expenseDataInitialState = {
        createdAt: new Date().toISOString(),
        amount: '',
        loanId: null,
        month: '',
        name: '',
        type: 'Expense',
        trackerId: '',
        actual: false
    }
    const [expenseFormData, setExpenseFormData] = useState(
        expenseDataInitialState
    )
    const { amount, month, name } = expenseFormData
    const [incomeFormData, setIncomeFormData] = useState({
        createdAt: new Date().toISOString(),
        amount: '',
        month: '',
        type: 'Income',
        incomeType: '',
        trackerId: '',
        actual: false
    })
    const params = useParams()

    const user = useSelector(selectUser)
    const trackerEntries = useSelector(selectTrackerEntries)
    const userId = user.uid

    const currentYear = new Date().getFullYear()
    let years = []
    for (let i = 0; i <= 15; i++) {
        years.push(currentYear + i)
    }

    useEffect(() => {
        async function getExpenseCategories() {
            setLoadingCategories(true)
            let categories = await getTrackerExpenseCategories(
                params.id,
                trackerEntries
            )
            setTrackerExpenseCategories(categories)
            setLoadingCategories(false)
        }

        getExpenseCategories()
        // getTracker()
    }, [trackerEntries])

    // OTHER
    const trackerEntriesCollectionRef = collection(db, 'tracker_entries')

    const closeModals = () => {
        setIsModalVisible(false)
    }

    const closeOverwritingModal = () => {
        setOverwritingModal(false)
    }

    const openModals = () => {
        setIsModalVisible(true)
    }

    const closeIncomeModals = () => {
        setIsIncomeModalVisible(false)
    }

    const openIncomeModals = () => {
        setIsIncomeModalVisible(true)
    }

    const onChange = (e) => {
        setExpenseFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const onIncomeChange = (e) => {
        setIncomeFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const onChangeCalculateAmount = (e) => {
        const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9])$/
        if (e.target.value === '' || re.test(e.target.value)) {
            setCalculateIncomeAmount((prevState) => ({
                ...prevState,
                [e.target.id]: e.target.value
            }))
        }
    }

    const onOtherIcomeAmountChange = (e) => {
        setOtherIncomeAmount(e.target.value)
    }

    const addExpense = async (e) => {
        e.preventDefault()

        try {
            setIsFormLoading(true)
            const expenseFormDataCopy = { ...expenseFormData }

            expenseFormDataCopy.trackerId = params.id
            expenseFormDataCopy.amount = Number(expenseFormDataCopy.amount)
            expenseFormDataCopy.userId = user.uid

            const filteredEntry = trackerEntries?.filter(
                (t) =>
                    t.data.trackerId === expenseFormDataCopy.trackerId &&
                    t.data.month === expenseFormDataCopy.month &&
                    t.data.name === expenseFormDataCopy.name &&
                    t.data.actual === false &&
                    t.data.type === 'Expense'
            )


            if (filteredEntry.length > 0) {
                const result = window.confirm(
                    'An entry for this month already exists. Do you like to override the value?'
                )

                if (result) {
                    const entryDoc = doc(
                        db,
                        'tracker_entries',
                        filteredEntry[0].id
                    )
                    const entryDocData = await getDoc(entryDoc)
                    const updates = {
                        amount: expenseFormDataCopy.amount
                    }
                    await updateDoc(entryDoc, updates)
                    toast.success('Successfuly added an Expense')
                }
            } else {
                await addDoc(trackerEntriesCollectionRef, expenseFormDataCopy)
                setExpenseFormData(expenseDataInitialState)
                toast.success('Successfuly added an Expense')
            }

            setIsFormLoading(false)
            setIsModalVisible(false)
        } catch (error) {
            setIsFormLoading(false)
            toast.error('Something went wrong')
        }
    }

    const addIncome = async (e) => {
        e.preventDefault()

        try {
            setIsFormLoading(true)
            const incomeFormDataCopy = { ...incomeFormData }

            incomeFormDataCopy.trackerId = params.id
            incomeFormDataCopy.amount =
                incomeFormDataCopy.incomeType === 'tracker'
                    ? Number(expectedIncome)
                    : Number(otherIncomeAmount)
            incomeFormDataCopy.userId = user.uid

            const filteredEntry = trackerEntries?.filter(
                (t) =>
                    t.data.trackerId === incomeFormDataCopy.trackerId &&
                    t.data.month === incomeFormDataCopy.month &&
                    t.data.incomeType === incomeFormDataCopy.incomeType &&
                    t.data.actual === false &&
                    t.data.type === 'Income'
            )


            if (filteredEntry.length > 0) {
                const result = window.confirm(
                    'An entry for this month already exists. Do you like to override the value?'
                )

                if (result) {
                    const entryDoc = doc(
                        db,
                        'tracker_entries',
                        filteredEntry[0].id
                    )
                    const entryDocData = await getDoc(entryDoc)
                    const updates = {
                        amount: incomeFormDataCopy.amount
                    }
                    await updateDoc(entryDoc, updates)
                    toast.success('Successfuly added an income')
                }
            } else {
                await addDoc(trackerEntriesCollectionRef, incomeFormDataCopy)
                toast.success('Successfuly added an income')
            }

            setIsFormLoading(false)
            setIsIncomeModalVisible(false)
        } catch (error) {
            setIsFormLoading(false)

            toast.error('Something went wrong')
        }
    }

    const updateTrackerLock = async (id, locked) => {
        const trackerDoc = doc(db, 'trackers', id)
        const newFields = { locked: !locked }
        await updateDoc(trackerDoc, newFields)
    }

    useEffect(() => {
        const getTracker = async () => {
            const trackerRef = doc(db, 'trackers', params.id)
            const trackerData = await getDoc(trackerRef)

            if (trackerData.exists()) {
                setTracker({ id: trackerData.id, data: trackerData.data() })
            } else {
                console.log('No such document!')
            }
        }

        getTracker()
    })

    let toRender
    let toRenderEmpty
    if (true) {
        toRender = (
            <>
                <table className='table tracker__entries__table table-sm fs-7 table-bordered table-hover align-middle gs-0 gy-4'>
                    <thead className='tracker__entries__thead'>
                        <tr className='fw-bolder text-dark'>
                            <th className='min-w-150px'></th>
                            {_months.map((month, index) => {
                                return (
                                    <th
                                        colSpan={2}
                                        key={month}
                                        className='min-w-120px text-uppercase'
                                    >
                                        {month}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                colSpan={1}
                                className='fw-bolder text-uppercase'
                            >
                                INCOME
                            </td>

                            <td
                                colSpan={24}
                                className='fw-bolder text-uppercase'
                            ></td>
                        </tr>
                        <tr>
                            <td>{params.trackerName}</td>
                            {_months.map((month, index) => {
                                return (
                                    <>
                                        <IncomeEntryCell
                                            id={params.id}
                                            month={month}
                                            incomeType='tracker'
                                            trackerEntries={trackerEntries}
                                        />
                                        <IncomeActualsEntryCell
                                            id={params.id}
                                            month={month}
                                            incomeType='tracker'
                                            trackerEntries={trackerEntries}
                                        />
                                    </>
                                )
                            })}
                        </tr>
                        <tr>
                            <td>Other Income</td>
                            {_months.map((month, index) => {
                                return (
                                    <>
                                        <IncomeEntryCell
                                            id={params.id}
                                            month={month}
                                            incomeType='otherIncome'
                                            trackerEntries={trackerEntries}
                                        />

                                        <IncomeActualsEntryCell
                                            id={params.id}
                                            month={month}
                                            incomeType='otherIncome'
                                            trackerEntries={trackerEntries}
                                        />
                                    </>
                                )
                            })}
                        </tr>
                        <tr>
                            <td className='fw-bolder'>Total Inflows</td>
                            {_months.map((month, index) => {
                                return (
                                    <>
                                        <IncomeMonthlyTotal
                                            id={params.id}
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            userId={userId}
                                        />
                                        <IncomeActualsMontlyTotal
                                            id={params.id}
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            userId={userId}
                                        />
                                    </>
                                )
                            })}
                        </tr>

                        <tr>
                            <td
                                colSpan={1}
                                className='fw-bolder text-uppercase'
                            >
                                EXPENSES
                            </td>

                            <td
                                colSpan={24}
                                className='fw-bolder text-uppercase'
                            ></td>
                        </tr>
                        {loadingCategories === false
                            ? trackerExpenseCategories != null
                                ? trackerExpenseCategories.map((category) => {
                                      return (
                                          <tr>
                                              <td>{category}</td>
                                              {_months.map((month, index) => {
                                                  return (
                                                      <>
                                                          <ExpenseEntryCell
                                                              id={params.id}
                                                              month={month}
                                                              name={category}
                                                              trackerEntries={
                                                                  trackerEntries
                                                              }
                                                          />
                                                          <ExpenseActualsEntryCell
                                                              id={params.id}
                                                              month={month}
                                                              name={category}
                                                              trackerEntries={
                                                                  trackerEntries
                                                              }
                                                          />
                                                      </>
                                                  )
                                              })}
                                          </tr>
                                      )
                                  })
                                : '⚠'
                            : 'Loading'}
                        <tr>
                            <td className='fw-bolder'>Total Outflows</td>
                            {_months.map((month, index) => {
                                return (
                                    <>
                                        <ExpensesMonthlyTotals
                                            id={params.id}
                                            month={month}
                                            trackerEntries={trackerEntries}
                                        />
                                        <ExpensesActualsMonthlyTotals
                                            id={params.id}
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            userId={userId}
                                        />
                                    </>
                                )
                            })}
                        </tr>

                        <tr>
                            <td className='fw-bolder text-uppercase'>
                                Net Totals
                            </td>
                            {_months.map((month, index) => {
                                return (
                                    <>
                                        <NetMonthlyTotalCell
                                            id={params.id}
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            userId={userId}
                                        />
                                        <NetMonthlyActualsTotalCell
                                            id={params.id}
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            userId={userId}
                                        />
                                    </>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            </>
        )
    } else if (true) {
        toRenderEmpty = (
            <>
                <Empty
                    className='mt-4'
                    imageStyle={{
                        height: 120
                    }}
                    description={
                        <h6 className='text-green-2 fw-600 fs-14px'>
                            Sorry there are no tracker entries found at the
                            moment
                        </h6>
                    }
                />
            </>
        )
    }

    return (
        <>
            <div className='d-flex flex-column flex-root'>
                <div className='page d-flex flex-row flex-column-fluid'>
                    <div
                        className='wrapper d-flex flex-column flex-row-fluid'
                        id='kt_wrapper'
                    >
                        <Nav />

                        <div
                            id='kt_content_container'
                            className='d-flex flex-column-fluid align-items-start container-xxl'
                        >
                            <div
                                className='content flex-row-fluid'
                                id='kt_content'
                            >
                                <div className='row gy-5 g-xl-8'>
                                    <div className='col-12'>
                                        <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                            <div className='card-header'>
                                                <h3 className='card-title align-items-start flex-column'>
                                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                                        Tracking{' '}
                                                        {params.trackerName}
                                                    </span>
                                                </h3>
                                                <div
                                                    className='card-toolbar'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    data-bs-trigger='hover'
                                                    title='Click to add a user'
                                                >
                                                    <a
                                                        href='#'
                                                        onClick={
                                                            openIncomeModals
                                                        }
                                                        className={`btn btn-sm btn-success btn-active-success ${
                                                            tracker?.data
                                                                ?.locked &&
                                                            'disabled'
                                                        }`}
                                                    >
                                                        Add Income
                                                    </a>
                                                    <a
                                                        href='#'
                                                        className={`btn btn-sm btn-primary btn-active-primary ms-3 ${
                                                            tracker?.data
                                                                ?.locked &&
                                                            'disabled'
                                                        }`}
                                                        onClick={openModals}
                                                    >
                                                        Add Expense
                                                    </a>
                                                    <a
                                                        href='#'
                                                        className={`btn btn-sm ms-3 ${
                                                            tracker?.data
                                                                ?.locked
                                                                ? 'btn-info'
                                                                : tracker.data ===
                                                                  undefined
                                                                ? 'disabled btn-warning'
                                                                : 'btn-warning'
                                                        }`}
                                                        onClick={() => {
                                                            updateTrackerLock(
                                                                tracker?.id,
                                                                tracker?.data
                                                                    ?.locked
                                                            )
                                                        }}
                                                    >
                                                        {tracker?.data?.locked
                                                            ? 'Locked'
                                                            : tracker.data ===
                                                              undefined
                                                            ? 'Loading...'
                                                            : 'Lock'}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='card-body py-3'>
                                                <div className='table-responsive'>
                                                    {toRender}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal
                            style={{ marginTop: '-60px' }}
                            visible={isModalVisible}
                            footer={false}
                            closable={isClosable}
                            onCancel={closeModals}
                            width={800}
                        >
                            <div className='modal-dialog modal-lg'>
                                <div className='modal-content border-0 rounded-0'>
                                    <div className='modal-header border-0 rounded-0 bg-light'>
                                        <h5
                                            className='modal-title text-dark fw-bold fs-3'
                                            id='add-productLabel'
                                        >
                                            Add Expense
                                        </h5>
                                    </div>
                                    <div className='modal-body border-0 rounded-0'>
                                        <Spin spinning={isFormLoading}>
                                            <form
                                                className='form w-100'
                                                onSubmit={addExpense}
                                            >
                                                <div className='row mb-7'>
                                                    <div className='col-md-6'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Expense Type
                                                        </label>
                                                        <select
                                                            className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                            id='name'
                                                            value={name}
                                                            onChange={onChange}
                                                        >
                                                            <option
                                                                disabled
                                                                value=''
                                                            >
                                                                Select Type
                                                            </option>
                                                            {_expenseCategories.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Month
                                                        </label>
                                                        <select
                                                            className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                            id='month'
                                                            value={month}
                                                            onChange={onChange}
                                                        >
                                                            in{' '}
                                                            <option
                                                                disabled
                                                                value=''
                                                            >
                                                                Select Month
                                                            </option>
                                                            {_months.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='fv-row mb-7'>
                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                        Amount
                                                    </label>
                                                    <input
                                                        className='form-control form-control-lg form-control-solid'
                                                        type='text'
                                                        id='amount'
                                                        value={amount}
                                                        onChange={onChange}
                                                        placeholder='e.g. $15.50'
                                                    />
                                                </div>

                                                <div className='text-center'>
                                                    <button
                                                        type='submit'
                                                        id='kt_sign_in_submit'
                                                        className='btn btn-lg btn-success w-100 mb-5'
                                                    >
                                                        <span className='indicator-label fw-700 text-uppercase'>
                                                            Continue
                                                        </span>
                                                    </button>
                                                </div>
                                            </form>
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            style={{ marginTop: '-60px' }}
                            visible={isIncomeModalVisible}
                            footer={false}
                            closable={isClosable}
                            onCancel={closeIncomeModals}
                            width={800}
                        >
                            <div className='modal-dialog modal-lg'>
                                <div className='modal-content border-0 rounded-0'>
                                    <div className='modal-header border-0 rounded-0 bg-light'>
                                        <h5
                                            className='modal-title text-dark fw-bold fs-3'
                                            id='add-productLabel'
                                        >
                                            Add Income
                                        </h5>
                                    </div>
                                    <div className='modal-body border-0 rounded-0'>
                                        <Spin spinning={isFormLoading}>
                                            <form
                                                className='form w-100'
                                                onSubmit={addIncome}
                                            >
                                                <div className='row mb-7'>
                                                    <div className='col-md-6'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Income Type
                                                        </label>
                                                        <select
                                                            className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                            id='incomeType'
                                                            value={
                                                                incomeFormData.incomeType
                                                            }
                                                            onChange={
                                                                onIncomeChange
                                                            }
                                                        >
                                                            <option
                                                                disabled
                                                                value=''
                                                            >
                                                                Select Type
                                                            </option>
                                                            <option value='tracker'>
                                                                {
                                                                    params.trackerName
                                                                }
                                                            </option>
                                                            <option value='otherIncome'>
                                                                Other Income
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Month
                                                        </label>
                                                        <select
                                                            className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                            id='month'
                                                            value={
                                                                incomeFormData.month
                                                            }
                                                            onChange={
                                                                onIncomeChange
                                                            }
                                                        >
                                                            <option
                                                                disabled
                                                                value=''
                                                            >
                                                                Select Month
                                                            </option>
                                                            {_months.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                {incomeFormData.incomeType ===
                                                'tracker' ? (
                                                    <>
                                                        <div className='fv-row mb-7'>
                                                            <label className='form-label fs-6 fw-bolder text-dark'>
                                                                Volume/Amount/Weight
                                                            </label>
                                                            <input
                                                                className='form-control form-control-lg form-control-solid'
                                                                type='text'
                                                                id='volume'
                                                                value={
                                                                    calculateIncomeAmount.volume
                                                                }
                                                                onChange={
                                                                    onChangeCalculateAmount
                                                                }
                                                                placeholder='e.g. 100 kgs'
                                                            />
                                                        </div>
                                                        <div className='fv-row mb-7'>
                                                            <label className='form-label fs-6 fw-bolder text-dark'>
                                                                Unit Value
                                                            </label>
                                                            <input
                                                                className='form-control form-control-lg form-control-solid'
                                                                type='text'
                                                                id='unitValue'
                                                                value={
                                                                    calculateIncomeAmount.unitValue
                                                                }
                                                                onChange={
                                                                    onChangeCalculateAmount
                                                                }
                                                                placeholder='e.g. $15.50/kg'
                                                            />
                                                        </div>
                                                        <div className='fv-row mb-7'>
                                                            <label className='form-label fs-6 fw-bolder text-dark'>
                                                                Expected Income
                                                            </label>
                                                            <input
                                                                className='form-control form-control-lg form-control-solid'
                                                                type='text'
                                                                id='amount'
                                                                value={
                                                                    expectedIncome
                                                                }
                                                                onChange={
                                                                    onIncomeChange
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className='fv-row mb-7'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Amount
                                                        </label>
                                                        <input
                                                            className='form-control form-control-lg form-control-solid'
                                                            type='text'
                                                            id='amount'
                                                            value={
                                                                otherIncomeAmount
                                                            }
                                                            onChange={
                                                                onOtherIcomeAmountChange
                                                            }
                                                        />
                                                    </div>
                                                )}

                                                <div className='text-center'>
                                                    <button
                                                        type='submit'
                                                        id='kt_sign_in_submit'
                                                        className='btn btn-lg btn-success w-100 mb-5'
                                                    >
                                                        <span className='indicator-label fw-700 text-uppercase'>
                                                            Continue
                                                        </span>
                                                    </button>
                                                </div>
                                            </form>
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            style={{ marginTop: '-60px' }}
                            visible={overwritingModal}
                            footer={false}
                            closable={isClosable}
                            onCancel={closeOverwritingModal}
                            width={400}
                        >
                            <div className='modal-dialog modal-lg'>
                                <div className='modal-content border-0 rounded-0'>
                                    <div className='modal-body border-0 rounded-0 text-center'>
                                        <h6>Duplicate Entry</h6>
                                        <p>
                                            An entry for this month already
                                            exists. What would you like to do?
                                        </p>
                                        <div>
                                            <a
                                                href='#'
                                                className='btn btn-sm btn-success btn-active-success'
                                                onClick={openIncomeModals}
                                            >
                                                Overwrite Existing
                                            </a>
                                            <a
                                                href='#'
                                                className='btn btn-sm btn-danger btn-active-danger ms-3'
                                                onClick={openModals}
                                            >
                                                Cancel Operation
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrackerEntries
