import { Link } from 'react-router-dom'
import { FARMER_PATHS } from 'routing'

const ErrorPage = () => {
  return (
    <div className='text-center pt-19'>
      <h1 className='text-danger text-center' style={{ fontSize: 40 }}>
        404, Page not found
      </h1>

      <Link to={FARMER_PATHS.dashboard} className='btn btn-primary'>
        Go to home
      </Link>
    </div>
  )
}

export default ErrorPage
