import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import userReducer from '../features/userSlice'
import trackerEntriesReducer from 'features/trackerEntriesSlice'
import trackersReducer from 'features/trackersSlice'

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
    user: userReducer,
    trackerEntries: trackerEntriesReducer,
    trackers: trackersReducer
})

const persistConfig = {
    key: 'root',
    version: 1,
    storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER
                ]
            }
        })
})
