import { Spin } from 'antd'
import BarChart from 'components/charts/BarChart'
import { _months } from 'data'
import { _chartData } from 'data/_chartData'
import { useEffect, useState } from 'react'
import { getIncomeTotalsPerMonth } from 'utils'
import { getIncomeActualsTotalsPerMonth } from 'utils/getIncomeActualsPerMonth'
import { useSelector } from 'react-redux'
import { selectUser } from 'features/userSlice'
import { selectTrackerEntries } from 'features/trackerEntriesSlice'

const ActualsVsEstimates = () => {
  const chartOptions = {
    maintainAspectRatio: false
  }
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getData()
  }, [])

  const user = useSelector(selectUser)
  const trackerEntries = useSelector(selectTrackerEntries)
  const userId = user.uid

  async function getData() {
    let allData = []
    for (let month of _months) {
      let monthlyIncomeActualsTotal = await getIncomeActualsTotalsPerMonth(
        month,
        trackerEntries,
        userId
      )
      let monthlyIncomeTotal = await getIncomeTotalsPerMonth(
        month,
        trackerEntries,
        userId
      )
      let data = {
        month,
        income: monthlyIncomeActualsTotal,
        incomeEstimate: monthlyIncomeTotal
      }

      allData.push(data)
    }

    setChartData({
      labels: allData.map((item) => item.month),
      datasets: [
        {
          label: 'Actuals',
          data: allData.map((item) => item.income),
          backgroundColor: ['#50cd89']
        },
        {
          label: 'Budget',
          data: allData.map((item) => item.incomeEstimate),
          backgroundColor: ['#f1416c']
        }
      ]
    })
    setLoading(false)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8 box-shadow-1'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              Income Actuals vs Budget
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <Spin spinning={loading}>
            <div className='canvas-container'>
              <BarChart chartData={chartData} chartOptions={chartOptions} />
            </div>
          </Spin>
        </div>
      </div>
    </>
  )
}

export default ActualsVsEstimates
