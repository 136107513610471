import { EditIcon } from 'components'
import { Link, useNavigate } from 'react-router-dom'
import { FARMER_PATHS } from 'routing'

const TrackersTable = (props) => {
  const {
    id,
    effectiveHectares,
    financialYear,
    trackerType,
    trackerName,
    createdAt,
    locked
  } = props

  let navigate = useNavigate()
  let date = new Date(createdAt).toLocaleDateString()

  const editTracker = () => {
    navigate({
      pathname: '/farmer/tracker-entries',
      state: {
        id: id
      }
    })
  }

  return (
    <>
      <tr>
        <td className='text-muted fw-bolder text-hover-success fs-7'>
          {effectiveHectares}
        </td>
        <td className='text-muted fw-bolder text-hover-success fs-7'>
          {financialYear}
        </td>
        <td className='text-muted fw-bolder text-hover-success fs-7'>
          {trackerName}
        </td>
        <td className='text-muted fw-bolder text-hover-success fs-7'>
          {trackerType}
        </td>
        <td className='text-muted fw-bolder text-hover-success fs-7'>{date}</td>
        <td className='text-muted fw-bolder text-hover-success fs-7 text-capitalize'>
          {JSON.stringify(locked)}
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <Link
              to={`${FARMER_PATHS.trackerEntries}${trackerName}/${id}`}
              className='btn btn-icon btn-success btn-sm me-1'
            >
              <span className='svg-icon svg-icon-3'>
                <EditIcon />
              </span>
            </Link>
          </div>
        </td>
      </tr>
    </>
  )
}

export default TrackersTable
