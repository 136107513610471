import { fCurrency } from 'utils'

const ExpensesMonthlyActualTotals = (
    month,
    trackerEntries,
    filteredTrackers
) => {
    let trackerIds = filteredTrackers?.map((tracker, index) => tracker.id)

    let expenseEntries = trackerEntries?.filter(
        (entry) =>
            entry.data.type == 'Expense' &&
            trackerIds.includes(entry.data.trackerId) &&
            entry.data.month == month &&
            entry.data.actual == true
    )

    return expenseEntries?.reduce((previous, current) => {
        return previous + current.data.amount
    }, 0)
}

export default ExpensesMonthlyActualTotals
