import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCCXMcRRuDBFaN-BOwcnjDqhsxp4FLboOc',
  authDomain: 'financial-app-b8c74.firebaseapp.com',
  projectId: 'financial-app-b8c74',
  storageBucket: 'financial-app-b8c74.appspot.com',
  messagingSenderId: '433292072094',
  appId: '1:433292072094:web:c666a27e624a28118f578f',
  measurementId: 'G-L3FESJ38QX',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
getAnalytics(app)
export const db = getFirestore()
export const firebaseAuth = getAuth(app)
