import { Empty, Spin } from 'antd'
import PieChart from 'components/charts/PieChart'
import { firebaseAuth } from 'config'
import { db } from 'config'
import { _months } from 'data'
import { _chartData } from 'data/_chartData'
import { selectUser } from 'features/userSlice'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { getNetMontlyActualsTotal } from 'utils/getNetMontlyActualsTotal'
import { useSelector } from 'react-redux'
import { selectTrackerEntries } from 'features/trackerEntriesSlice'

const TrackersDistribution = () => {
  const chartOptions = {
    maintainAspectRatio: false
  }
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  })
  const [loading, setLoading] = useState(true)
  const trackersRef = collection(db, 'trackers')
  const user = useSelector(selectUser)
  const trackerEntries = useSelector(selectTrackerEntries)
  const userId = user.uid

  useEffect(() => {
    const fetchTrackers = async () => {
      const q = query(trackersRef, where('userId', '==', user.uid))
      setLoading(true)

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const trackersList = []
        querySnapshot.forEach((doc) => {
          trackersList.push({
            id: doc.id,
            data: doc.data()
          })
        })

        let allData = []

        for (let tracker of trackersList) {
          let trackerYearlyTotal = 0
          for (let month of _months) {
            let monthlyNetTotal = await getNetMontlyActualsTotal(
              tracker.id,
              month,
              trackerEntries,
              userId
            )
            trackerYearlyTotal += monthlyNetTotal
          }

          let trackerData = {
            tracker: tracker.data.trackerType.name,
            amount: trackerYearlyTotal
          }

          allData.push(trackerData)
        }

        setChartData({
          labels: allData.map((item) => item.tracker),
          datasets: [
            {
              label: 'Expenses',
              data: allData.map((item) => item.amount),
              backgroundColor: [
                '#50cd89',
                '#f1416c',
                '#009ef7',
                '#ffc700',
                '#7239ea'
              ]
            }
          ]
        })

        setLoading(false)
      })
    }

    fetchTrackers()
  }, [])

  return (
    <>
      <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              Trackers Income Distribution
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <Spin spinning={loading}>
            <div className='canvas-container'>
              {chartData.labels.length > 0 ? (
                <PieChart chartOptions={chartOptions} chartData={chartData} />
              ) : chartData.labels.length === 0 && loading === false ? (
                <Empty
                  imageStyle={{
                    height: 120
                  }}
                  description={
                    <h6 className='text-green-2 fw-600 fs-14px'>
                      Sorry, no data to show at the moment.
                    </h6>
                  }
                />
              ) : (
                ''
              )}
            </div>
          </Spin>
        </div>
      </div>
    </>
  )
}

export default TrackersDistribution
