import { getIncomePerMonth } from './getIncomePerMonth'
import { getExpensesPerMonth } from './getExpensesPerMonth'

export const getNetMonthlyTotal = async (id, month, trackerEntries, userId) => {
  let monthlyIncome = await getIncomePerMonth(id, month, trackerEntries, userId)
  let monthlyExpenses = await getExpensesPerMonth(
    id,
    month,
    trackerEntries,
    userId
  )

  return (monthlyIncome ?? 0) - (monthlyExpenses ?? 0)
}
