import { fCurrency } from 'utils'
import ExpensesMonthlyTotals from './netTotals/ExpensesMonthlyTotals'
import IncomeMonthlyTotal from './netTotals/IncomeMonthlyTotal'

const NetMonthlyTotalCell = ({ month, trackerEntries, filteredTrackers }) => {
    let monthlyIncome = IncomeMonthlyTotal(
        month,
        trackerEntries,
        filteredTrackers
    )
    let monthlyExpenses = ExpensesMonthlyTotals(
        month,
        trackerEntries,
        filteredTrackers
    )

    let result = (monthlyIncome ?? 0) - (monthlyExpenses ?? 0)

    return (
        <td className={`${result >= 0 ? '' : 'text-danger'}`}>
            {fCurrency(result)}
        </td>
    )
}

export default NetMonthlyTotalCell
