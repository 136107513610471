import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  trackerEntries: []
}

const trackerEntriesSlice = createSlice({
  name: 'trackerEntries',
  initialState,
  reducers: {
    addTrackerEntries: (state, action) => {
      return { ...state, trackerEntries: [...action.payload] }
    },
    resetTrackerEntries: (state, action) => {
      state.trackerEntries = null
    }
  }
})

export const { addTrackerEntries, resetTrackerEntries } =
  trackerEntriesSlice.actions

export const selectTrackerEntries = (state) =>
  state.trackerEntries.trackerEntries
// first trackerEntries is defined in trackerEntriesslice_name & 2nd in initialstate

export default trackerEntriesSlice.reducer
