import { fCurrency } from 'utils'
import ExpensesMonthlyActualTotals from './netTotals/ExpensesMonthlyActualTotals'
import IncomeMonthlyActualTotals from './netTotals/IncomeMonthlyActualTotals'

const NetMonthlyActualsTotalCell = ({
    month,
    trackerEntries,
    filteredTrackers
}) => {
    let monthlyIncome = IncomeMonthlyActualTotals(
        month,
        trackerEntries,
        filteredTrackers
    )
    let monthlyExpenses = ExpensesMonthlyActualTotals(
        month,
        trackerEntries,
        filteredTrackers
    )

    let result = (monthlyIncome ?? 0) - (monthlyExpenses ?? 0)

    return (
        <td className={`${result >= 0 ? 'text-success' : 'text-danger'}`}>
            {fCurrency(result)}
        </td>
    )
}

export default NetMonthlyActualsTotalCell
