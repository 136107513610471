import { Routes } from 'routing'
import { db } from 'config'
import {enableIndexedDbPersistence} from "firebase/firestore";

(async function () {
    try {
        await enableIndexedDbPersistence(db)
    } catch(error) {
        if (error.code === 'failed-precondition') {
            console.log("Multiple tabs probably opened. Can only be enabled on one tab")
        } else if (error.code === 'unimplemented') {
            console.log("Current browser does not support requested feature")
        }
        console.log("Error here: ", error)
    }
})()

const App = () => <div>{Routes()}</div>

export default App
