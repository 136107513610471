import { Footer, Junk, Nav } from 'components'
import { getAuth } from 'firebase/auth'
import IncomeAndExpenses from './charts/IncomeAndExpenses'
import TrackersDistribution from './charts/TrackersDistribution'
import ActualsVsEstimates from './charts/ActualsVsEstimates'

const FarmerDashboard = () => {
  const auth = getAuth()

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          <div
            className='wrapper d-flex flex-column flex-row-fluid'
            id='kt_wrapper'
          >
            <Nav />

            <div
              id='kt_content_container'
              className='d-flex flex-column-fluid align-items-start container-xxl'
            >
              <div className='content flex-row-fluid' id='kt_content'>
                <div className='row gy-5 g-xl-8'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <TrackersDistribution />
                    </div>
                    <div className='col-md-8'>
                      <IncomeAndExpenses />
                    </div>
                    <div className='col-12'>
                      <ActualsVsEstimates />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default FarmerDashboard
