import { fCurrency } from 'utils'

const IncomeMonthlyTotal = (month, trackerEntries, filteredTrackers) => {
    console.log(trackerEntries)

    let trackerIds = filteredTrackers?.map((tracker, index) => tracker.id)

    let incomeEntries = trackerEntries?.filter(
        (entry) =>
            entry.data.type == 'Income' &&
            trackerIds.includes(entry.data.trackerId) &&
            entry.data.month == month &&
            entry.data.actual == false
    )

    return incomeEntries?.reduce((previous, current) => {
        return previous + current.data.amount
    }, 0)
}

export default IncomeMonthlyTotal
