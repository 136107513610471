import { fCurrency, getExpensesPerMonth } from '../../utils'
import { Spinner } from 'components'
import React, { useEffect, useState } from 'react'
import { getNetMonthlyTotal } from '../../utils/getNetMontlyTotal'
import { getNetMontlyActualsTotal } from 'utils/getNetMontlyActualsTotal'

const NetMonthlyActualsTotalCell = ({ id, month, trackerEntries, userId }) => {
  const [total, setTotal] = useState(null)

  useEffect(() => {
    getNetMontlyActualsTotal(id, month, trackerEntries, userId).then((item) => {
      setTotal(item)
    })
  }, [trackerEntries])

  return total == null ? (
    <td className='fw-bolder'>
      <Spinner />
    </td>
  ) : (
    <td
      className={`fw-bolder text-success ${
        total >= 0 ? '' : 'text-danger fw-bold'
      }`}
    >
      {fCurrency(total)}
    </td>
  )
}

export default NetMonthlyActualsTotalCell
