export const PUBLIC_ROOT = '/'
export const FARMER_ROOT = '/farmer'

function path(root, sublink) {
    return `${root}${sublink}`
}

export const FARMER_PATHS = {
    dashboard: path(FARMER_ROOT, '/dashboard'),
    profile: path(FARMER_ROOT, '/profile'),
    trackers: path(FARMER_ROOT, '/trackers'),
    trackerEntries: path(FARMER_ROOT, '/tracker-entries/'),
    loanCalculator: path(FARMER_ROOT, '/loan-calculator'),
    annualProfitAndLoss: path(FARMER_ROOT, '/annual-profit-and-loss'),
    dailyTransactions: path(FARMER_ROOT, '/daily-transactions')
}

export const PUBLIC_PATHS = {
    forgotPassword: path(PUBLIC_ROOT, 'forgot-password')
}
