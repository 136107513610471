import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../config'

const trackerEntriesIncomeCollectionRef = collection(db, 'tracker_entries')

export async function getTrackerExpenseCategories(id, trackerEntries) {
  try {
    const data = await trackerEntries
    const filteredData = data?.filter(
      (t) => t.data.trackerId === id && t.data.type === 'Expense'
    )

    let categories = filteredData.map((doc) => doc.data.name)
    let categoriesSet = new Set(categories)
    let categoriesList = Array.from(categoriesSet)
    return categoriesList
  } catch (error) {
    console.log(error)
    return null
  }
}
