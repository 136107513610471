import { fCurrency, getExpenseEntry } from '../../utils'
import { Spinner } from 'components'
import { useEffect, useState } from 'react'

const ExpenseEntryCell = ({ id, month, name, trackerEntries }) => {
  const [entry, setEntry] = useState(null)

  useEffect(() => {
    getExpenseEntry(id, month, name, trackerEntries).then((item) => {
      setEntry(item)
    })
  }, [trackerEntries])

  return entry == null ? (
    <td>
      <Spinner />
    </td>
  ) : entry.data == null ? (
    <td> - </td>
  ) : (
    <td>{fCurrency(entry.data.amount)}</td>
  )
}

export default ExpenseEntryCell
