import { collection, getDocs, query, where } from 'firebase/firestore'
import { db, firebaseAuth } from '../config'

const trackerEntriesIncomeCollectionRef = collection(db, 'tracker_entries')

export async function getExpensesActualsPerMonth(
  id,
  month,
  trackerEntries,
  userId
) {
  try {
    const data = await trackerEntries
    const filteredData = data?.filter(
      (t) =>
        t.data.type === 'Expense' &&
        t.data.trackerId === id &&
        t.data.month === month &&
        t.data.actual === true &&
        t.data.userId === userId
    )

    let total = 0

    for (let doc in filteredData) {
      let monthlyData = filteredData[doc]
      total += monthlyData.data.amount
    }

    return total
  } catch (error) {
    console.log(error)
    return 0
  }
}

export async function getExpensesActualsTotalsPerMonth(
  month,
  trackerEntries,
  userId
) {
  try {
    const data = await trackerEntries
    const filteredData = data?.filter(
      (t) =>
        t.data.type === 'Expense' &&
        t.data.month === month &&
        t.data.actual === true &&
        t.data.userId === userId
    )

    let total = 0

    for (let doc in filteredData) {
      let monthlyData = filteredData[doc]
      total += monthlyData.data.amount
    }

    return total
  } catch (error) {
    console.log(error)
    return 0
  }
}
