export const _expenseCategories = [
    'Procurement Costs',
    'Wages',
    'Rent',
    'Transportation',
    'Utilities',
    'Insurance',
    'Accidental Loss',
    'Supplies',
    'Repairs and Maintenance',
    'Fuel',
    'Communication / Telephone',
    'Interest and Taxes',
    'Depreciation',
    'Consultancy',
    'Electricity',
    'Family Drawings',
    'Hired Labor',
    'Taxes',
    'Chemicals',
    'Vet & Medicine'
]
