import { useEffect, useState } from 'react'
import { FiUser, FiMail, FiPhone } from 'react-icons/fi'
import { getAuth, updateProfile } from 'firebase/auth'
import {
    collection,
    addDoc,
    query,
    where,
    onSnapshot,
    getDoc,
    doc,
    updateDoc
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { Empty, Spin, Modal } from 'antd'
import 'antd/dist/antd.css'
import Avatar from 'assets/img/avatar.png'
import { db } from 'config'
import {
    ActionsIcon,
    DeleteIcon,
    EditIcon,
    Footer,
    Nav,
    Spinner
} from 'components'
import { Link } from 'react-router-dom'
import { async } from '@firebase/util'

const Farms = () => {
    // STATE
    const auth = getAuth()

    const farmsCollectionRef = collection(db, 'farms')
    const user = auth.currentUser

    const [isFormLoading, setIsFormLoading] = useState(false)
    const [isClosable, setIsClosable] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [farms, setFarms] = useState(null)
    const [userProfile, setUserProfile] = useState([])
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        hectares: '',
        numberOfEmployees: '',
        createdAt: new Date().toISOString()
    })
    const [editProfileData, setEditProfileData] = useState({
        lastName: '',
        firstName: '',
        phoneNumber: ''
    })
    const [userData, setUserData] = useState({
        name: auth.currentUser.displayName,
        email: auth.currentUser.email
    })

    const { name, address, hectares, numberOfEmployees, id } = formData

    // OTHER
    const closeModals = () => {
        setIsModalVisible(false)
    }

    const openModals = () => {
        setIsModalVisible(true)
    }

    // REQUESTS
    useEffect(() => {
        fetchfarms()
    }, [])

    useEffect(() => {
        const fetchUserProfile = async () => {
            const userRef = collection(db, 'profiles')
            const q = query(userRef, where('userId', '==', user.uid))

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const userData = []
                querySnapshot.forEach((doc) => {
                    userData.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })
                setUserProfile(userData)
                setLoading(false)
            })
        }

        fetchUserProfile()
    }, [])

    const fetchfarms = async () => {
        const q = query(farmsCollectionRef, where('userId', '==', user.uid))

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const farmsData = []
            querySnapshot.forEach((doc) => {
                farmsData.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            setFarms(farmsData)
            setLoading(false)
        })
    }

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const onEditChange = (e) => {
        setEditProfileData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const editProfile = async (e) => {
        e.preventDefault()

        try {
            setIsFormLoading(true)

            updateProfile(auth.currentUser, {
                displayName:
                    editProfileData.firstName + ' ' + editProfileData.lastName
            })

            const userDoc = doc(db, 'profiles', user.uid)
            const updates = {
                phoneNumber: editProfileData.phoneNumber,
                lastName: editProfileData.lastName,
                firstName: editProfileData.firstName
            }
            await updateDoc(userDoc, updates)

            setEditProfileData({
                lastName: '',
                firstName: '',
                phoneNumber: ''
            })

            // getUserProfile()
            toast.success('Successfully updated profile')
            setIsFormLoading(false)
            setEditModal(false)
        } catch (error) {
            setIsFormLoading(false)
            toast.error('Failed to update details')
        }
    }

    const addFarm = async (e) => {
        e.preventDefault()

        try {
            setIsFormLoading(true)
            const formDataCopy = { ...formData }

            formDataCopy.userId = user.uid

            await addDoc(farmsCollectionRef, formDataCopy)

            setIsFormLoading(false)
            setIsModalVisible(false)

            fetchfarms()
        } catch (error) {
            setIsFormLoading(false)

            toast.error('Something went wrong')
        }
    }

    if (loading) {
        return <Spinner />
    }

    let toRender
    let toRenderEmpty
    if (farms?.length > 0 && loading === false) {
        toRender = (
            <>
                {!!farms &&
                    farms.map((item) => {
                        return (
                            <div className='card-body p-9' key={item.id}>
                                <p className='text-gray-700 fw-bold fs-5 mt-1 mb-0'>
                                    {item.data.name}
                                </p>
                                <p className='text-gray-400 fw-bold fs-7 mb-7'>
                                    Located in {item.data.address}
                                </p>
                                <div className='d-flex flex-wrap mb-5'>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                                        <div className='fs-6 text-gray-800 fw-bolder'>
                                            Date Created
                                        </div>
                                        <div className='fw-bold text-gray-400'>
                                            {item.data.createdAt}
                                        </div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                                        <div className='fs-6 text-gray-800 fw-bolder'>
                                            Size (Hectares)
                                        </div>
                                        <div className='fw-bold text-gray-400'>
                                            {item.data.hectares}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </>
        )
    } else if (loading === false) {
        toRenderEmpty = (
            <>
                <Empty
                    className='mt-4'
                    imageStyle={{
                        height: 120
                    }}
                    description={
                        <h6 className='text-green-2 fw-600 fs-14px'>
                            Sorry there are no farms found at the moment
                        </h6>
                    }
                />
            </>
        )
    }

    return (
        <>
            <div className='d-flex flex-column flex-root'>
                <div className='page d-flex flex-row flex-column-fluid'>
                    <div
                        className='wrapper d-flex flex-column flex-row-fluid'
                        id='kt_wrapper'
                    >
                        <Nav />

                        {!loading && (
                            <>
                                <div
                                    id='kt_content_container'
                                    className='d-flex flex-column-fluid align-items-start container-xxl'
                                >
                                    <div
                                        className='content flex-row-fluid'
                                        id='kt_content'
                                    >
                                        <div className='row gy-5 g-xl-8'>
                                            <div className='col-12 col-md-6'>
                                                <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                                    <div className='card-body pt-9 pb-0'>
                                                        <a
                                                            onClick={() =>
                                                                setEditModal(
                                                                    true
                                                                )
                                                            }
                                                            href='#'
                                                            className='btn btn-success float-end'
                                                        >
                                                            Edit
                                                        </a>
                                                        <div className='d-flex flex-wrap flex-sm-nowrap'>
                                                            <div className='me-7 mb-4'>
                                                                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                                                    <img
                                                                        src={
                                                                            Avatar
                                                                        }
                                                                        alt='image'
                                                                    />
                                                                    <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px' />
                                                                </div>
                                                            </div>
                                                            <div className='flex-grow-1'>
                                                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                                                    <Spin
                                                                        spinning={
                                                                            loading
                                                                        }
                                                                    >
                                                                        <div className='d-flex flex-column'>
                                                                            <div className='d-flex align-items-center mb-2'>
                                                                                <a
                                                                                    href='#'
                                                                                    className='text-gray-900 text-hover-primary fs-2 fw-bolder me-1 text-capitalize'
                                                                                >
                                                                                    {
                                                                                        userProfile[0]
                                                                                            ?.data
                                                                                            ?.firstName
                                                                                    }{' '}
                                                                                    {
                                                                                        userProfile[0]
                                                                                            ?.data
                                                                                            ?.lastName
                                                                                    }
                                                                                </a>
                                                                            </div>
                                                                            <div>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <a
                                                                                        href='#'
                                                                                        className='d-flex align-items-center text-gray-400 text-hover-primary'
                                                                                    >
                                                                                        <span className='svg-icon svg-icon-4 me-1'>
                                                                                            <FiUser />
                                                                                        </span>
                                                                                    </a>

                                                                                    <a
                                                                                        href='#'
                                                                                        className='text-gray-500 text-hover-primary fs-6 fw-bolder ms-3'
                                                                                    >
                                                                                        {
                                                                                            userProfile[0]
                                                                                                ?.data
                                                                                                ?.role
                                                                                        }
                                                                                    </a>
                                                                                </div>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <a
                                                                                        href='#'
                                                                                        className='d-flex align-items-center text-gray-400 text-hover-primary'
                                                                                    >
                                                                                        <span className='svg-icon svg-icon-4 me-1'>
                                                                                            <FiPhone />
                                                                                        </span>
                                                                                    </a>

                                                                                    <a
                                                                                        href='#'
                                                                                        className='text-gray-500 text-hover-primary fs-6 fw-bolder ms-3'
                                                                                    >
                                                                                        {
                                                                                            userProfile[0]
                                                                                                ?.data
                                                                                                ?.phoneNumber
                                                                                        }
                                                                                    </a>
                                                                                </div>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <a
                                                                                        href='#'
                                                                                        className='d-flex align-items-center text-gray-400 text-hover-primary'
                                                                                    >
                                                                                        <span className='svg-icon svg-icon-4 me-1'>
                                                                                            <FiMail />
                                                                                        </span>
                                                                                    </a>

                                                                                    <a
                                                                                        href='#'
                                                                                        className='text-gray-500 text-hover-primary fs-6 fw-bolder ms-3'
                                                                                    >
                                                                                        {
                                                                                            userProfile[0]
                                                                                                ?.data
                                                                                                ?.email
                                                                                        }
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Spin>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6'>
                                                <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                                    <div className='card-header d-flex align-items-center'>
                                                        <h3 className='fs-3 fw-bolder text-dark'>
                                                            Farm
                                                        </h3>

                                                        <div>
                                                            <a
                                                                href='#'
                                                                className={`btn btn-sm btn-success btn-active-success ${
                                                                    farms?.length >=
                                                                    1
                                                                        ? 'd-none'
                                                                        : ''
                                                                }`}
                                                                onClick={
                                                                    openModals
                                                                }
                                                            >
                                                                Add
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {toRender}
                                                    <center>
                                                        {toRenderEmpty}
                                                        <Spin
                                                            spinning={loading}
                                                        />
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Modal
                                    style={{ marginTop: '-60px' }}
                                    visible={isModalVisible}
                                    footer={false}
                                    closable={isClosable}
                                    onCancel={closeModals}
                                    width={800}
                                >
                                    <div className='modal-dialog modal-lg'>
                                        <div className='modal-content border-0 rounded-0'>
                                            <div className='modal-header border-0 rounded-0 bg-light'>
                                                <h5
                                                    className='modal-title text-dark fw-bold fs-3'
                                                    id='add-productLabel'
                                                >
                                                    Add Farm
                                                </h5>
                                            </div>
                                            <div className='modal-body border-0 rounded-0'>
                                                <Spin spinning={isFormLoading}>
                                                    <form
                                                        className='form w-100'
                                                        onSubmit={addFarm}
                                                    >
                                                        <div className='row mb-7'>
                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6 fw-bolder text-dark'>
                                                                    Name
                                                                </label>
                                                                <input
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    type='text'
                                                                    id='name'
                                                                    value={name}
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    autoComplete='off'
                                                                />
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6 fw-bolder text-dark'>
                                                                    Size
                                                                    (Hectares)
                                                                </label>
                                                                <input
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    type='number'
                                                                    id='hectares'
                                                                    value={
                                                                        hectares
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    autoComplete='off'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fv-row mb-7'>
                                                            <label className='form-label fs-6 fw-bolder text-dark'>
                                                                Address
                                                            </label>
                                                            <input
                                                                className='form-control form-control-lg form-control-solid'
                                                                type='text'
                                                                id='address'
                                                                value={address}
                                                                onChange={
                                                                    onChange
                                                                }
                                                                autoComplete='off'
                                                            />
                                                        </div>
                                                        <div className='fv-row mb-7'>
                                                            <label className='form-label fs-6 fw-bolder text-dark'>
                                                                Number of
                                                                employees
                                                            </label>
                                                            <input
                                                                className='form-control form-control-lg form-control-solid'
                                                                type='number'
                                                                id='numberOfEmployees'
                                                                value={
                                                                    numberOfEmployees
                                                                }
                                                                onChange={
                                                                    onChange
                                                                }
                                                                autoComplete='off'
                                                            />
                                                        </div>

                                                        <div className='text-center'>
                                                            <button
                                                                type='submit'
                                                                id='kt_sign_in_submit'
                                                                className='btn btn-lg btn-success w-100 mb-5'
                                                            >
                                                                <span className='indicator-label fw-700 text-uppercase'>
                                                                    Continue
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </Spin>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal
                                    style={{ marginTop: '-60px' }}
                                    visible={editModal}
                                    footer={false}
                                    closable={isClosable}
                                    onCancel={() => setEditModal(false)}
                                    width={800}
                                >
                                    <div className='modal-dialog modal-lg'>
                                        <div className='modal-content border-0 rounded-0'>
                                            <div className='modal-header border-0 rounded-0 bg-light'>
                                                <h5
                                                    className='modal-title text-dark fw-bold fs-3'
                                                    id='add-productLabel'
                                                >
                                                    Edit Profile
                                                </h5>
                                            </div>
                                            <div className='modal-body border-0 rounded-0'>
                                                <Spin spinning={isFormLoading}>
                                                    {!loading && (
                                                        <form
                                                            className='form w-100'
                                                            onSubmit={
                                                                editProfile
                                                            }
                                                        >
                                                            <div className='row mb-7'>
                                                                <div className='col-md-6'>
                                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                                        First
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        className='form-control form-control-lg form-control-solid'
                                                                        type='text'
                                                                        id='firstName'
                                                                        value={
                                                                            editProfileData.firstName
                                                                        }
                                                                        onChange={
                                                                            onEditChange
                                                                        }
                                                                        autoComplete='off'
                                                                    />
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                                        Last
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        className='form-control form-control-lg form-control-solid'
                                                                        type='text'
                                                                        id='lastName'
                                                                        value={
                                                                            editProfileData.lastName
                                                                        }
                                                                        onChange={
                                                                            onEditChange
                                                                        }
                                                                        autoComplete='off'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='row mb-7'>
                                                                <div className='col-12'>
                                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                                        Phone
                                                                        Number
                                                                    </label>
                                                                    <input
                                                                        className='form-control form-control-lg form-control-solid'
                                                                        type='text'
                                                                        id='phoneNumber'
                                                                        value={
                                                                            editProfileData.phoneNumber
                                                                        }
                                                                        onChange={
                                                                            onEditChange
                                                                        }
                                                                        autoComplete='off'
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='text-center'>
                                                                <button
                                                                    type='submit'
                                                                    id='kt_sign_in_submit'
                                                                    className='btn btn-lg btn-success w-100 mb-5'
                                                                >
                                                                    <span className='indicator-label fw-700 text-uppercase'>
                                                                        Submit
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Spin>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </>
                        )}
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Farms
