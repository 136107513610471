import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    trackerEntries: []
}

const trackersSlice = createSlice({
    name: 'trackers',
    initialState,
    reducers: {
        addTrackers: (state, action) => {
            return { ...state, trackers: [...action.payload] }
        },
        resetTrackers: (state, action) => {
            state.trackers = null
        }
    }
})

export const { addTrackers, resetTrackers } = trackersSlice.actions

export const selectTrackers = (state) => state.trackers.trackers
// first trackerEntries is defined in trackersslice_name & 2nd in initialstate

export default trackersSlice.reducer
