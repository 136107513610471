import React from 'react'

const Slider = ({ marks, min, max, defaultValue, handleChange, title }) => {
  return (
    <>
      <h5 className='text-center'>
        {defaultValue} {title}
      </h5>
      <div className='d-flex'>
        <p>{min}</p>
        <input
          type='range'
          className='form-range mx-3'
          defaultValue={defaultValue}
          onChange={handleChange}
          min={min}
          max={max}
        />
        <p>{max}</p>
      </div>
    </>
  )
}

export default Slider
