import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth'
import { toast } from 'react-toastify'
import { firebaseAuth } from 'config'
import { FARMER_PATHS } from 'routing'
import { useAuth, useAuthStatus } from 'hooks'
const ForgotPassword = () => {
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onChange = (e) => {
    setEmail(e.target.value)
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      await sendPasswordResetEmail(firebaseAuth, email)
      setLoading(false)
      setEmail('')
      toast.success('Email was sent')
    } catch (error) {
      setLoading(false)
      toast.error('Could not send the reset email')
    }
  }

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-lg-500px bg-body rounded box-shadow-1 p-10 p-lg-15 mx-auto vertically-centered-form'>
              <form className='form w-100' onSubmit={onSubmit}>
                <div className='text-center mb-10'>
                  <h1 className='text-dark mb-3'>Forgot Password ?</h1>
                  <div className='text-gray-400 fw-bold fs-4'>
                    Enter your email to reset your password.
                  </div>
                </div>
                <div className='fv-row mb-10'>
                  <div className='d-flex justify-content-between'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      Email
                    </label>
                    <Link to='/' className='link-success fs-6 fw-bolder'>
                      Login
                    </Link>
                  </div>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    id='email'
                    value={email}
                    onChange={onChange}
                    autoComplete='off'
                  />
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className={`btn btn-lg btn-success w-100 mb-5 ${
                      loading && 'disabled'
                    }`}
                  >
                    <span className='indicator-label fw-700 text-uppercase'>
                      {loading ? 'Please Wait...' : 'Continue'}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
