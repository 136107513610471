import { fCurrency, getExpensesPerMonth } from 'utils'
import { Spinner } from 'components'
import { useEffect, useState } from 'react'
import { getExpensesActualsPerMonth } from 'utils/getExpensesActualsPerMonth'

const ExpensesActualsMonthlyTotals = ({
  id,
  month,
  trackerEntries,
  userId
}) => {
  const [total, setTotal] = useState(null)

  useEffect(() => {
    getExpensesActualsPerMonth(id, month, trackerEntries, userId).then(
      (item) => {
        setTotal(item)
      }
    )
  }, [trackerEntries])

  return total == null ? (
    <td>
      <Spinner />
    </td>
  ) : (
    <td className='fw-bolder text-success'>{fCurrency(total)}</td>
  )
}

export default ExpensesActualsMonthlyTotals
