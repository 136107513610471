import { fCurrency, getIncomePerMonth } from '../../utils'
import { Spinner } from 'components'
import { useEffect, useState } from 'react'

const IncomeMonthlyTotal = ({ id, month, trackerEntries, userId }) => {
  const [total, setTotal] = useState(null)

  useEffect(() => {
    getIncomePerMonth(id, month, trackerEntries, userId).then((item) => {
      setTotal(item)
    })
  }, [trackerEntries])

  return total == null ? (
    <td>
      <Spinner />
    </td>
  ) : (
    <td className='fw-bolder'>{fCurrency(total)}</td>
  )
}

export default IncomeMonthlyTotal
