import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'antd/dist/antd.css'
import { _months, _trackerTypes, _expenseCategories } from 'data'
import { Footer, Nav } from 'components'

import IncomeEntryCell from './annualProfitAndLossReport/IncomeEntryCell'
import IncomeActualsEntryCell from './annualProfitAndLossReport/IncomeActualsEntryCell'
import IncomeMonthlyTotal from './annualProfitAndLossReport/IncomeMontlyTotal'
import { getTrackerExpenseCategories } from '../../utils/getTrackerExpenseCategories'
import ExpenseEntryCell from './annualProfitAndLossReport/ExpenseEntryCell'
import ExpensesMonthlyTotals from './annualProfitAndLossReport/ExpensesMonthlyTotals'
import NetMonthlyTotalCell from './annualProfitAndLossReport/NetMonthlyTotalCell'
import IncomeActualsMontlyTotal from './annualProfitAndLossReport/IncomeActualsMontlyTotal'
import ExpenseActualsEntryCell from './annualProfitAndLossReport/ExpenseActualsEntryCell'
import ExpensesActualsMonthlyTotals from './annualProfitAndLossReport/ExpensesActualsMonthlyTotals'
import NetMonthlyActualsTotalCell from './annualProfitAndLossReport/NetMonthlyActualsTotalCell'

import { useSelector } from 'react-redux'
import { selectUser } from 'features/userSlice'
import { selectTrackerEntries } from 'features/trackerEntriesSlice'
import { selectTrackers } from 'features/trackersSlice'

const FarmerAnnualProfitAndLossReport = () => {
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [trackerExpenseCategories, setTrackerExpenseCategories] = useState([])
    const params = useParams()
    const user = useSelector(selectUser)
    const trackerEntries = useSelector(selectTrackerEntries)
    const trackers = useSelector(selectTrackers)
    const userId = user.uid
    const year = params.year

    const filteredTrackers = trackers.filter(
        (tracker, index) => tracker.data.financialYear === year
    )

    useEffect(() => {
        async function getExpenseCategories() {
            setLoadingCategories(true)
            let categories = await getTrackerExpenseCategories(
                params.id,
                trackerEntries
            )
            setTrackerExpenseCategories(categories)
            setLoadingCategories(false)
        }

        getExpenseCategories()
    }, [trackerEntries])

    function getYearExpenseCategories() {
        let trackerIds = filteredTrackers.map((tracker, index) => tracker.id)

        let expenseEntries = trackerEntries.filter(
            (entry) =>
                entry.data.type == 'Expense' &&
                trackerIds.includes(entry.data.trackerId)
        )

        let expenseCategories = expenseEntries.map((entry) => entry.data.name)

        let expenseCategoriesSet = new Set(expenseCategories)

        let expenseCategoriesList = Array.from(expenseCategoriesSet)
        return expenseCategoriesList
    }

    let toRender
    let toRenderEmpty
    if (true) {
        toRender = (
            <>
                <table className='table tracker__entries__table table-sm fs-7 table-bordered table-hover align-middle gs-0 gy-4'>
                    <thead className='tracker__entries__thead'>
                        <tr className='fw-bolder text-dark'>
                            <th className='min-w-150px'></th>
                            {_months.map((month, index) => {
                                return (
                                    <th
                                        colSpan={2}
                                        key={month}
                                        className='min-w-120px text-uppercase'
                                    >
                                        {month}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                colSpan={1}
                                className='fw-bolder text-uppercase'
                            >
                                INCOME
                            </td>
                            <td
                                colSpan={24}
                                className='fw-bolder text-uppercase'
                            ></td>
                        </tr>

                        {filteredTrackers.map((tracker, index) => {
                            return (
                                <>
                                    <tr>
                                        <td>{tracker.data.trackerType.name}</td>
                                        {_months.map((month, index) => {
                                            return (
                                                <>
                                                    <IncomeEntryCell
                                                        trackerId={tracker.id}
                                                        month={month}
                                                        trackerEntries={
                                                            trackerEntries
                                                        }
                                                        filteredTrackers={
                                                            filteredTrackers
                                                        }
                                                    />
                                                    <IncomeActualsEntryCell
                                                        trackerId={tracker.id}
                                                        month={month}
                                                        trackerEntries={
                                                            trackerEntries
                                                        }
                                                        filteredTrackers={
                                                            filteredTrackers
                                                        }
                                                    />
                                                </>
                                            )
                                        })}
                                    </tr>
                                </>
                            )
                        })}
                        <tr>
                            <td className='fw-bolder'>Total Inflows</td>
                            {_months.map((month, index) => {
                                return (
                                    <>
                                        <IncomeMonthlyTotal
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            filteredTrackers={filteredTrackers}
                                        />
                                        <IncomeActualsMontlyTotal
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            filteredTrackers={filteredTrackers}
                                        />
                                    </>
                                )
                            })}
                        </tr>

                        <tr>
                            <td
                                colSpan={1}
                                className='fw-bolder text-uppercase'
                            >
                                EXPENSES
                            </td>
                            <td
                                colSpan={24}
                                className='fw-bolder text-uppercase'
                            ></td>
                        </tr>
                        {getYearExpenseCategories().map((category) => {
                            return (
                                <tr>
                                    <td>{category}</td>

                                    {_months.map((month, index) => {
                                        return (
                                            <>
                                                <ExpenseEntryCell
                                                    month={month}
                                                    trackerEntries={
                                                        trackerEntries
                                                    }
                                                    filteredTrackers={
                                                        filteredTrackers
                                                    }
                                                    category={category}
                                                />
                                                <ExpenseActualsEntryCell
                                                    month={month}
                                                    trackerEntries={
                                                        trackerEntries
                                                    }
                                                    filteredTrackers={
                                                        filteredTrackers
                                                    }
                                                    category={category}
                                                />
                                            </>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        <tr>
                            <td className='fw-bolder'>Total Outflows</td>
                            {_months.map((month, index) => {
                                return (
                                    <>
                                        <ExpensesMonthlyTotals
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            filteredTrackers={filteredTrackers}
                                        />
                                        <ExpensesActualsMonthlyTotals
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            filteredTrackers={filteredTrackers}
                                        />
                                    </>
                                )
                            })}
                        </tr>

                        <tr>
                            <td className='fw-bolder text-uppercase'>
                                Net Totals
                            </td>
                            {_months.map((month, index) => {
                                return (
                                    <>
                                        <NetMonthlyTotalCell
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            filteredTrackers={filteredTrackers}
                                        />
                                        <NetMonthlyActualsTotalCell
                                            month={month}
                                            trackerEntries={trackerEntries}
                                            filteredTrackers={filteredTrackers}
                                        />
                                    </>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <>
            <div className='d-flex flex-column flex-root'>
                <div className='page d-flex flex-row flex-column-fluid'>
                    <div
                        className='wrapper d-flex flex-column flex-row-fluid'
                        id='kt_wrapper'
                    >
                        <Nav />

                        <div
                            id='kt_content_container'
                            className='d-flex flex-column-fluid align-items-start container-xxl'
                        >
                            <div
                                className='content flex-row-fluid'
                                id='kt_content'
                            >
                                <div className='row gy-5 g-xl-8'>
                                    <div className='col-12'>
                                        <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                            <div className='card-header'>
                                                <h3 className='card-title align-items-start flex-column'>
                                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                                        Profit & Loss Statement
                                                        for {params.year}{' '}
                                                        Financial Year
                                                    </span>
                                                </h3>
                                            </div>
                                            <div className='card-body py-3'>
                                                <div className='table-responsive'>
                                                    {toRender}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FarmerAnnualProfitAndLossReport
