import { getIncomeActualsPerMonth } from './getIncomeActualsPerMonth'
import { getExpensesActualsPerMonth } from './getExpensesActualsPerMonth'

export const getNetMontlyActualsTotal = async (
  id,
  month,
  trackerEntries,
  userId
) => {
  let monthlyIncome = await getIncomeActualsPerMonth(
    id,
    month,
    trackerEntries,
    userId
  )
  let monthlyExpenses = await getExpensesActualsPerMonth(
    id,
    month,
    trackerEntries,
    userId
  )

  return (monthlyIncome ?? 0) - (monthlyExpenses ?? 0)
}
