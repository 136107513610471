import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import {
    collection,
    getDocs,
    addDoc,
    query,
    where,
    doc,
    getDoc
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { Spin, Modal } from 'antd'
import 'antd/dist/antd.css'
import { db } from 'config'
import { Footer, Nav, Slider, Spinner } from 'components'
import { _months } from 'data'
import { fCurrency } from 'utils'

const LoanCalculator = () => {
    // STATE
    const [tenureMonthsValue, setTenureMonthsValue] = useState(3)
    const [interestRateValue, setInterestRateValue] = useState(1)
    const [principalValue, setPrincipalValue] = useState(0)
    const [repaymentMonth, setRepaymentMonth] = useState('January')
    const [trackerId, setTrackerId] = useState('')
    const [loanName, setLoanName] = useState('')
    const [isFormLoading, setIsFormLoading] = useState(false)
    const [isClosable, setIsClosable] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [trackers, setTrackers] = useState(null)
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [tracker, setTracker] = useState({})
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        hectares: '',
        numberOfEmployees: '',
        createdAt: new Date().toISOString()
    })

    let monthlyInterest = interestRateValue / 100 / 12
    let monthlyPayment =
        (principalValue *
            monthlyInterest *
            Math.pow(1 + monthlyInterest, tenureMonthsValue)) /
        (Math.pow(1 + monthlyInterest, tenureMonthsValue) - 1)
    let totalLoanRepaymentAmount = monthlyPayment * tenureMonthsValue
    let totalInterest = totalLoanRepaymentAmount - principalValue
    let leftover = totalLoanRepaymentAmount % tenureMonthsValue

    let repayments = []
    let monthsIndex = _months.indexOf(repaymentMonth)

    for (let i = monthsIndex; i < tenureMonthsValue + monthsIndex; i++) {
        repayments.push({
            month: _months[i % _months.length],
            amount: monthlyPayment
        })
    }

    if (leftover !== 0) {
        if (repayments.length > 0) {
            repayments[repayments.length - 1].amount += leftover
        }
    }

    const auth = getAuth()
    const [userData, setUserData] = useState({
        name: auth.currentUser.displayName,
        email: auth.currentUser.email
    })

    const { name, address, hectares, numberOfEmployees, id } = formData

    // OTHER
    const navigate = useNavigate()

    const closeModals = () => {
        setIsModalVisible(false)
    }

    const openModals = () => {
        setIsModalVisible(true)
    }

    const monthMarks = [
        {
            value: 3,
            label: '3'
        },
        {
            value: 48,
            label: '48'
        }
    ]

    // REQUESTS
    useEffect(() => {
        fetchTrackers()
    }, [])

    const trackersRef = collection(db, 'trackers')
    const loansRef = collection(db, 'loans')
    const trackerEntriesRef = collection(db, 'tracker_entries')
    const user = auth.currentUser

    const fetchTrackers = async () => {
        const q = query(trackersRef, where('userId', '==', user.uid))

        const data = await getDocs(q)

        let trackersData = []
        data.forEach((doc) => {
            return trackersData.push({
                id: doc.id,
                data: doc.data()
            })
        })

        setTrackers(trackersData)
        setLoading(false)
    }

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const onChangeSetTenureMonths = (e) => {
        setTenureMonthsValue(Number(e.target.value))
    }

    const onChangeInterestRate = (e) => {
        setInterestRateValue(Number(e.target.value))
    }

    const onChangePrincipalValue = (e) => {
        setPrincipalValue(Number(e.target.value))
    }

    const onChangeMonth = (e) => {
        setRepaymentMonth(e.target.value)
    }

    const onChangeTrackerId = (e) => {
        setTrackerId(e.target.value)
    }

    const onChangeLoanName = (e) => {
        setLoanName(e.target.value)
    }

    useEffect(() => {
        const getTracker = async () => {
            const trackerRef = doc(db, 'trackers', trackerId)
            const trackerData = await getDoc(trackerRef)

            if (trackerData.exists()) {
                setTracker({ id: trackerData.id, data: trackerData.data() })
            } else {
                console.log('No such document!')
            }
        }

        getTracker()
    }, [trackerId])

    const addLoan = async (e) => {
        e.preventDefault()

        try {
            if (principalValue > 0) {
                setIsFormLoading(true)
                const loanData = {
                    createdAt: new Date().toISOString(),
                    amount: principalValue,
                    interestRate: interestRateValue,
                    loanTerm: tenureMonthsValue,
                    totalRepayment: totalLoanRepaymentAmount,
                    trackerId: '',
                    userId: '',
                    name: loanName
                }

                loanData.userId = user.uid
                loanData.trackerId = trackerId
                let loanDoc = await addDoc(loansRef, loanData)
                for (let repayment in repayments) {
                    let loanTrackerData = {
                        createdAt: new Date().toISOString(),
                        amount: repayments[repayment].amount,
                        trackerId: trackerId,
                        month: repayments[repayment].month,
                        type: 'Expense',
                        name: loanName,
                        loanId: loanDoc.id,
                        actual: false,
                        userId: user.uid
                    }

                    await addDoc(trackerEntriesRef, loanTrackerData)
                }

                setIsFormLoading(false)
                setIsModalVisible(false)
                toast.success('Successfuly created loan')
            }
        } catch (error) {
            setIsFormLoading(false)
            toast.error('Something went wrong')
        }
    }

    if (loading) {
        return <Spinner />
    }

    return (
        <>
            <div className='d-flex flex-column flex-root'>
                <div className='page d-flex flex-row flex-column-fluid'>
                    <div
                        className='wrapper d-flex flex-column flex-row-fluid'
                        id='kt_wrapper'
                    >
                        <Nav />

                        <div
                            id='kt_content_container'
                            className='d-flex flex-column-fluid align-items-start container-xxl'
                        >
                            <div
                                className='content flex-row-fluid'
                                id='kt_content'
                            >
                                <div className='row gy-5 g-xl-8'>
                                    <div className='col-12'>
                                        <form
                                            className='form w-100'
                                            onSubmit={addLoan}
                                        >
                                            <div className='row'>
                                                <div className='col-md-2 d-none d-md-inline-block' />
                                                <div className='col-md-8'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                                                <div className='card-body'>
                                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                                        Loan
                                                                        Amount
                                                                    </label>
                                                                    <input
                                                                        className='form-control form-control-lg form-control-solid'
                                                                        type='text'
                                                                        value={
                                                                            principalValue
                                                                        }
                                                                        onChange={
                                                                            onChangePrincipalValue
                                                                        }
                                                                        autoComplete='off'
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                                                <div className='card-body'>
                                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                                        Select
                                                                        Loan
                                                                        Tenure
                                                                    </label>
                                                                    <p className='text-muted'>
                                                                        Move
                                                                        slider
                                                                        to
                                                                        select
                                                                        the
                                                                        collateral
                                                                        term in
                                                                        months
                                                                    </p>
                                                                    <Slider
                                                                        min='3'
                                                                        max='48'
                                                                        defaultValue={
                                                                            tenureMonthsValue
                                                                        }
                                                                        handleChange={
                                                                            onChangeSetTenureMonths
                                                                        }
                                                                        title='Months'
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                                                <div className='card-body'>
                                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                                        Select
                                                                        Interest
                                                                        rate
                                                                    </label>
                                                                    <p className='text-muted'>
                                                                        Move
                                                                        slider
                                                                        to
                                                                        select
                                                                        the
                                                                        interest
                                                                        rate to
                                                                        be
                                                                        applied
                                                                        on the
                                                                        loan
                                                                    </p>
                                                                    <Slider
                                                                        min='1'
                                                                        max='100'
                                                                        defaultValue={
                                                                            interestRateValue
                                                                        }
                                                                        handleChange={
                                                                            onChangeInterestRate
                                                                        }
                                                                        title='%'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-6'>
                                                            <div className='card card-xl-stretch mb-5 mb-xl-8 box-shadow-1'>
                                                                <div className='card-body'>
                                                                    <h4 className='fw-600'>
                                                                        Summary
                                                                    </h4>
                                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                                        Monthly
                                                                        Cost for{' '}
                                                                        {
                                                                            tenureMonthsValue
                                                                        }{' '}
                                                                        Months
                                                                    </label>
                                                                    <p className='text-muted'>
                                                                        You're
                                                                        borrowing
                                                                        or you
                                                                        borrowed{' '}
                                                                        {fCurrency(
                                                                            principalValue
                                                                        )}{' '}
                                                                        for over{' '}
                                                                        {
                                                                            tenureMonthsValue
                                                                        }{' '}
                                                                        months
                                                                        with a
                                                                        total
                                                                        loan
                                                                        cost of{' '}
                                                                        {fCurrency(
                                                                            totalLoanRepaymentAmount
                                                                        )}
                                                                        . Please
                                                                        confirm
                                                                        and
                                                                        continue.
                                                                    </p>

                                                                    <div className='table-responsive'>
                                                                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                        Loan
                                                                                        Amount
                                                                                    </td>
                                                                                    <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                        {fCurrency(
                                                                                            principalValue
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                        Total
                                                                                        Loan
                                                                                        Repayment
                                                                                        Amount
                                                                                    </td>
                                                                                    <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                        {fCurrency(
                                                                                            totalLoanRepaymentAmount
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                        Monthly
                                                                                        Repayment
                                                                                    </td>
                                                                                    <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                        {fCurrency(
                                                                                            monthlyPayment
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                        Total
                                                                                        Interest
                                                                                    </td>
                                                                                    <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                        {fCurrency(
                                                                                            totalInterest
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                        <h1 className='text-center'>
                                                                            {fCurrency(
                                                                                totalLoanRepaymentAmount
                                                                            )}
                                                                        </h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <a
                                                                href
                                                                className='btn btn-lg btn-success w-100 mb-5 box-shadow-1'
                                                                onClick={
                                                                    openModals
                                                                }
                                                            >
                                                                <span className='indicator-label fw-700 text-uppercase'>
                                                                    Push To
                                                                    Budget
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-2 d-none d-md-inline-block' />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal
                            style={{ marginTop: '-60px' }}
                            visible={isModalVisible}
                            footer={false}
                            closable={isClosable}
                            onCancel={closeModals}
                            width={800}
                        >
                            <div className='modal-dialog modal-lg'>
                                <div className='modal-content border-0 rounded-0'>
                                    <div className='modal-header border-0 rounded-0 bg-light'>
                                        <h5
                                            className='modal-title text-dark fw-600 fs-3'
                                            id='add-productLabel'
                                        >
                                            Loan Details
                                        </h5>
                                    </div>
                                    <div className='modal-body border-0 rounded-0'>
                                        <Spin spinning={isFormLoading}>
                                            <form
                                                className='form w-100'
                                                onSubmit={addLoan}
                                            >
                                                <div className='row mb-7'>
                                                    <div className='fv-row col-md-6'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Loan Name
                                                        </label>
                                                        <input
                                                            className='form-control form-control-lg form-control-solid'
                                                            type='text'
                                                            value={loanName}
                                                            onChange={
                                                                onChangeLoanName
                                                            }
                                                            autoComplete='off'
                                                            required
                                                            placeholder='Loan Name'
                                                        />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className='form-label fs-6 fw-bolder text-dark'>
                                                            Which month are you
                                                            starting repaying
                                                            the loan?
                                                        </label>
                                                        <select
                                                            className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                            value={
                                                                repaymentMonth
                                                            }
                                                            onChange={
                                                                onChangeMonth
                                                            }
                                                            required
                                                        >
                                                            <option
                                                                disabled
                                                                value=''
                                                            >
                                                                Select Month
                                                            </option>
                                                            {_months.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                                <hr className=' bg-gray-400' />

                                                <h5>Repayment Breakdown</h5>

                                                <div>
                                                    <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
                                                        <tbody>
                                                            {repayments.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                {
                                                                                    item.month
                                                                                }
                                                                            </td>
                                                                            <td className='text-muted fw-bolder text-hover-success fs-7'>
                                                                                {fCurrency(
                                                                                    item.amount
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <hr className=' bg-gray-400' />

                                                <div className='fv-row mb-7'>
                                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                                        Select Tracker
                                                    </label>
                                                    <select
                                                        className='mb-2 form-control form-control-lg form-control-solid form-select'
                                                        value={trackerId}
                                                        onChange={
                                                            onChangeTrackerId
                                                        }
                                                    >
                                                        <option
                                                            disabled
                                                            value=''
                                                        >
                                                            Select Tracker
                                                        </option>
                                                        {trackers.map(
                                                            (item, index) => {
                                                                return (
                                                                    <option
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item
                                                                                .data
                                                                                .trackerType
                                                                                .name
                                                                        }
                                                                    </option>
                                                                )
                                                            }
                                                        )}
                                                    </select>
                                                </div>

                                                <div className='text-center'>
                                                    <button
                                                        type='submit'
                                                        id='kt_sign_in_submit'
                                                        className='btn btn-lg btn-success w-100 mb-5'
                                                    >
                                                        <span className='indicator-label fw-700 text-uppercase'>
                                                            Post To Tracker
                                                        </span>
                                                    </button>
                                                </div>
                                            </form>
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoanCalculator
