import { fCurrency } from 'utils'

const ExpenseActualsEntryCell = ({
    month,
    trackerEntries,
    filteredTrackers,
    category
}) => {
    function getEntryCellAmount() {
        let trackerIds = filteredTrackers.map((tracker, index) => tracker.id)

        let expenseEntries = trackerEntries.filter(
            (entry) =>
                entry.data.type == 'Expense' &&
                trackerIds.includes(entry.data.trackerId) &&
                entry.data.month == month &&
                entry.data.actual == true &&
                entry.data.name == category
        )

        return expenseEntries?.reduce((previous, current) => {
            return previous + current.data.amount
        }, 0)
    }
    return <td className='text-success'>{fCurrency(getEntryCellAmount())}</td>
}

export default ExpenseActualsEntryCell
