import { collection, query, where, getDocs } from 'firebase/firestore'
import { db } from '../config/FirebaseConfig'
import { v4 as uuidv4 } from 'uuid'

const trackerEntriesIncomeCollectionRef = collection(db, 'tracker_entries')

export const getIncomeEntry = async (
  trackerId,
  month,
  incomeType,
  trackerEntries
) => {
  const data = await trackerEntries
  const filteredData = data?.filter(
    (t) =>
      t.data.trackerId === trackerId &&
      t.data.month === month &&
      t.data.actual === false &&
      t.data.incomeType === incomeType
  )

  if (filteredData.length > 0) {
    return {
      id: filteredData[0].id,
      month: month,
      data: filteredData[0].data
    }
  } else {
    return {
      id: uuidv4(),
      month: month,
      data: null
    }
  }
}
