import { fCurrency, getExpensesPerMonth } from 'utils'
import { Spinner } from 'components'
import { useEffect, useState } from 'react'

const ExpensesMonthlyTotals = ({ id, month, trackerEntries }) => {
  const [total, setTotal] = useState(null)

  useEffect(() => {
    getExpensesPerMonth(id, month, trackerEntries).then((item) => {
      setTotal(item)
    })
  }, [trackerEntries])

  return total == null ? (
    <td>
      <Spinner />
    </td>
  ) : (
    <td className='fw-bolder'>{fCurrency(total)}</td>
  )
}

export default ExpensesMonthlyTotals
