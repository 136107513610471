import { fCurrency, getIncomePerMonth } from '../../utils'
import { Spinner } from 'components'
import { useEffect, useState } from 'react'
import { getIncomeActualsPerMonth } from 'utils/getIncomeActualsPerMonth'

const IncomeActualsMontlyTotal = ({ id, month, trackerEntries, userId }) => {
  const [total, setTotal] = useState(null)

  useEffect(() => {
    getIncomeActualsPerMonth(id, month, trackerEntries, userId).then((item) => {
      setTotal(item)
    })
  }, [trackerEntries])

  return total == null ? (
    <td>
      <Spinner />
    </td>
  ) : (
    <td className='fw-bolder text-success'>{fCurrency(total)}</td>
  )
}

export default IncomeActualsMontlyTotal
