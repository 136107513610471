import { fCurrency } from '../../utils'
import { useEffect, useState } from 'react'
import { Spinner } from 'components'
import { getActualsIncomeEntry } from 'utils/getActualsIncomeEntry'

const IncomeActualsEntryCell = ({ id, month, incomeType, trackerEntries }) => {
  const [entry, setEntry] = useState(null)

  useEffect(() => {
    getActualsIncomeEntry(id, month, incomeType, trackerEntries).then(
      (item) => {
        setEntry(item)
      }
    )
  }, [trackerEntries])

  return entry == null ? (
    <td>
      <Spinner />
    </td>
  ) : entry.data == null ? (
    <td className='text-success'> - </td>
  ) : (
    <td className='text-success'>{fCurrency(entry.data.amount)}</td>
  )
}

export default IncomeActualsEntryCell
