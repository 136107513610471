import LogoWhite from 'assets/img/afriblocks-logo.svg'
import Logo from 'assets/img/afriblocks-logo.svg'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'
import { FARMER_PATHS } from 'routing'
import BarsIcon from './icons/BarsIcon'

import { Drawer, Button, Space } from 'antd'

const Nav = (props) => {
    const navigate = useNavigate()
    const onLogout = () => {
        auth.signOut()
        navigate('/')
    }

    const auth = getAuth()
    const [formData, setformData] = useState({
        name: auth.currentUser.displayName,
        email: auth.currentUser.email
    })

    const { name, email } = formData

    const [visible, setVisible] = useState(false)
    const [size, setSize] = useState()

    const showDefaultDrawer = () => {
        setSize('default')
        setVisible(true)
    }

    const onClose = () => {
        setVisible(false)
    }

    return (
        <>
            <Drawer
                placement='left'
                size={size}
                onClose={onClose}
                visible={visible}
            >
                <hr className='m-0 bg-dark' />

                <div className='menu-item me-lg-1'>
                    <span className='menu-link py-3'>
                        <Link
                            to={FARMER_PATHS.dashboard}
                            className='menu-title text-dark text-hover-success'
                        >
                            Dashboard
                        </Link>
                    </span>
                </div>

                <hr className='m-0 bg-dark' />

                <div className='menu-item me-lg-1'>
                    <span className='menu-link py-3'>
                        <Link
                            to={FARMER_PATHS.profile}
                            className='menu-title text-dark text-hover-success'
                        >
                            Profile
                        </Link>
                    </span>
                </div>

                <hr className='m-0 bg-dark' />

                <div className='menu-item me-lg-1'>
                    <span className='menu-link py-3'>
                        <Link
                            to={FARMER_PATHS.trackers}
                            className='menu-title text-dark text-hover-success'
                        >
                            Trackers
                        </Link>
                    </span>
                </div>

                <hr className='m-0 bg-dark' />

                <div className='menu-item me-lg-1'>
                    <span className='menu-link py-3'>
                        <Link
                            to={FARMER_PATHS.dailyTransactions}
                            className='menu-title text-dark text-hover-success'
                        >
                            Daily Transactions
                        </Link>
                    </span>
                </div>

                <hr className='m-0 bg-dark' />

                <div className='menu-item me-lg-1'>
                    <span className='menu-link py-3'>
                        <Link
                            to={FARMER_PATHS.loanCalculator}
                            className='menu-title text-dark text-hover-success'
                        >
                            Loan Calculator
                        </Link>
                    </span>
                </div>

                <hr className='m-0 bg-dark' />
            </Drawer>

            <div
                id='kt_header'
                className='header align-items-stretch'
                data-kt-sticky='true'
                data-kt-sticky-name='header'
                data-kt-sticky-offset="{default: '200px', lg: '300px'}"
            >
                <div className='container-xxl d-flex align-items-center'>
                    <div
                        onClick={showDefaultDrawer}
                        className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
                        title='Show aside menu'
                    >
                        <div
                            className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
                            id='kt_header_menu_mobile_toggle'
                        >
                            <span className='svg-icon svg-icon-1'>
                                <BarsIcon />
                            </span>
                        </div>
                    </div>
                    <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0'>
                        <a href='/'>
                            <img
                                alt='Logo'
                                src={LogoWhite}
                                className='logo-default h-25px'
                            />
                            <img
                                alt='Logo'
                                src={Logo}
                                className='logo-sticky h-25px'
                            />
                        </a>
                    </div>

                    <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
                        <div
                            className='d-flex align-items-stretch'
                            id='kt_header_nav'
                        >
                            <div
                                className='header-menu align-items-stretch'
                                data-kt-drawer='true'
                                data-kt-drawer-name='header-menu'
                                data-kt-drawer-activate='{default: true, lg: false}'
                                data-kt-drawer-overlay='true'
                                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                                data-kt-drawer-direction='start'
                                data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
                                data-kt-swapper='true'
                                data-kt-swapper-mode='prepend'
                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                            >
                                <div
                                    className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'
                                    id='#kt_header_menu'
                                    data-kt-menu='true'
                                >
                                    <div className='menu-item me-lg-1'>
                                        <span className='menu-link py-3'>
                                            <Link
                                                to={FARMER_PATHS.dashboard}
                                                className='menu-title'
                                            >
                                                Dashboard
                                            </Link>
                                        </span>
                                    </div>
                                    <div className='menu-item me-lg-1'>
                                        <span className='menu-link py-3'>
                                            <Link
                                                to={FARMER_PATHS.profile}
                                                className='menu-title'
                                            >
                                                Profile
                                            </Link>
                                        </span>
                                    </div>
                                    <div className='menu-item me-lg-1'>
                                        <span className='menu-link py-3'>
                                            <Link
                                                to={FARMER_PATHS.trackers}
                                                className='menu-title'
                                            >
                                                Trackers
                                            </Link>
                                        </span>
                                    </div>

                                    <div className='menu-item me-lg-1'>
                                        <span className='menu-link py-3'>
                                            <Link
                                                to={
                                                    FARMER_PATHS.dailyTransactions
                                                }
                                                className='menu-title'
                                            >
                                                Daily Transactions
                                            </Link>
                                        </span>
                                    </div>

                                    <div className='menu-item me-lg-1'>
                                        <span className='menu-link py-3'>
                                            <Link
                                                to={FARMER_PATHS.loanCalculator}
                                                className='menu-title'
                                            >
                                                Loan Calculator
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='dropdown'>
                            <button
                                className='btn bg-transparent text-white dropdown-toggle bg-transparent text-white fs-7 fw-bolder lh-1'
                                type='button'
                                id='dropdownMenuButton1'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                            >
                                {name}
                            </button>
                            <ul
                                className='dropdown-menu'
                                aria-labelledby='dropdownMenuButton1'
                            >
                                <li>
                                    <Link
                                        className='dropdown-item'
                                        to={FARMER_PATHS.profile}
                                    >
                                        My Profile
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        onClick={onLogout}
                                        className='dropdown-item'
                                        href='#'
                                    >
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Nav
