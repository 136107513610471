import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile
} from 'firebase/auth'
import { setDoc, doc, serverTimestamp } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { db } from 'config'

const Register = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    gender: '',
    phoneNumber: '',
    role: ''
  })

  const { email, password, firstName, lastName, gender, phoneNumber, role } =
    formData
  const navigate = useNavigate()

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const auth = getAuth()

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      const user = userCredential.user

      updateProfile(auth.currentUser, {
        displayName: firstName + ' ' + lastName
      })

      const formDataCopy = { ...formData }
      delete formDataCopy.password

      formDataCopy.userId = user.uid

      await setDoc(doc(db, 'profiles', user.uid), formDataCopy)

      setLoading(false)
      navigate('/')
    } catch (error) {
      setLoading(false)
      toast.error('Bad user credentials')
    }
  }

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-lg-500px bg-body rounded box-shadow-1 p-10 p-lg-15 mx-auto'>
              <form className='form w-100' onSubmit={onSubmit}>
                <div className='text-center mb-10'>
                  <h1 className='text-dark mb-3'>Create an Account</h1>
                  <div className='text-gray-400 fw-bold fs-4'>
                    Already have an account?
                    <Link to='/' className='link-success fw-bolder ms-3'>
                      Sign In
                    </Link>
                  </div>
                </div>
                <div className='row fv-row mb-7'>
                  <div className='col-xl-6 mb-7 mb-xl-0'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      First Name
                    </label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      placeholder
                      id='firstName'
                      value={firstName}
                      onChange={onChange}
                      autoComplete='off'
                    />
                  </div>
                  <div className='col-xl-6'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      Last Name
                    </label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      placeholder
                      id='lastName'
                      value={lastName}
                      onChange={onChange}
                      autoComplete='off'
                    />
                  </div>
                </div>
                <div className='fv-row mb-7'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    Email
                  </label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='email'
                    id='email'
                    value={email}
                    onChange={onChange}
                    autoComplete='off'
                  />
                </div>
                <div className='fv-row mb-7'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    Phone Number
                  </label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    id='phoneNumber'
                    value={phoneNumber}
                    onChange={onChange}
                    autoComplete='off'
                  />
                </div>
                <div className='fv-row mb-7'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    Gender
                  </label>
                  <select
                    className='mb-2 form-control form-control-lg form-control-solid form-select'
                    id='gender'
                    value={gender}
                    onChange={onChange}
                  >
                    <option disabled value=''>
                      Select Option
                    </option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                  </select>
                </div>
                <div className='fv-row mb-7'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    Role
                  </label>
                  <select
                    className='mb-2 form-control form-control-lg form-control-solid form-select'
                    id='role'
                    value={role}
                    onChange={onChange}
                  >
                    <option disabled value=''>
                      Select Option
                    </option>
                    <option value='Farmer'>Farmer</option>
                    <option value='Financial Advisor'>Financial Advisor</option>
                    <option value='Agricultural Advisor'>
                      Agricultural Advisor
                    </option>
                  </select>
                </div>
                <div>
                  <div className='mb-10 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Password
                      </label>
                      <div className='position-relative mb-3'>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          type='password'
                          placeholder
                          id='password'
                          autoComplete='off'
                          value={password}
                          onChange={onChange}
                        />
                        <span
                          onClick={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                          className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                          data-kt-password-meter-control='visibility'
                        >
                          <i className='bi bi-eye-slash fs-2' />
                          <i className='bi bi-eye fs-2 d-none' />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className={`btn btn-lg btn-success w-100 mb-5 ${
                      loading && 'disabled'
                    }`}
                  >
                    <span className='indicator-label fw-700 text-uppercase'>
                      {loading ? 'Please Wait...' : 'Continue'}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register
