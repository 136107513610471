import { firebaseAuth } from 'config'
import { login, logout, selectUser } from 'features/userSlice'
import {
    ErrorPage,
    FarmerDashboard,
    FarmerLoanCalculator,
    FarmerProfile,
    FarmerTrackerEntries,
    FarmerTrackers,
    Login,
    Register
} from 'pages'
import ForgotPassword from 'pages/ForgotPassword'
import { useEffect, useState } from 'react'
import { Routes as Router, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PrivateRoute } from 'routing'
import { FARMER_PATHS, PUBLIC_PATHS } from './Paths'
import { useSelector, useDispatch } from 'react-redux'
import {
    addTrackerEntries,
    resetTrackerEntries,
    selectTrackerEntries
} from 'features/trackerEntriesSlice'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from 'config'
import { Spinner } from 'components'
import FarmerDailyTransactions from 'pages/farmer/DailyTransactions'
import FarmerAnnualProfitAndLossReport from 'pages/farmer/AnnualProfitAndLossReport'
import { addTrackers, resetTrackers } from 'features/trackersSlice'

const Routes = () => {
    const [loading, setLoading] = useState(false)
    const user = useSelector(selectUser)
    const trackerEntriesFromState = useSelector(selectTrackerEntries)

    const dispatch = useDispatch()

    useEffect(() => {
        firebaseAuth.onAuthStateChanged((userAuth) => {
            if (userAuth) {
                // User is signed in
                dispatch(
                    login({
                        email: userAuth.email,
                        uid: userAuth.uid,
                        displayName: userAuth.displayName
                    })
                )
            } else {
                // User is signed out
                dispatch(logout())
            }
        })
    }, [dispatch])

    useEffect(() => {
        const trackerEntriesRef = collection(db, 'tracker_entries')

        const fetchTrackerEntries = async () => {
            if (user) {
                setLoading(true)
                const q = query(
                    trackerEntriesRef,
                    where('userId', '==', user.uid)
                )

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const trackersEntriesData = []
                    querySnapshot.forEach((doc) => {
                        trackersEntriesData.push({
                            id: doc.id,
                            data: doc.data()
                        })
                    })
                    dispatch(addTrackerEntries(trackersEntriesData))
                    setLoading(false)
                })
            } else {
                dispatch(resetTrackerEntries())
            }
        }

        fetchTrackerEntries()
    }, [user])

    useEffect(() => {
        const trackersRef = collection(db, 'trackers')

        const fetchTrackers = async () => {
            if (user) {
                setLoading(true)
                const q = query(trackersRef, where('userId', '==', user.uid))

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const trackersData = []
                    querySnapshot.forEach((doc) => {
                        trackersData.push({
                            id: doc.id,
                            data: doc.data()
                        })
                    })
                    dispatch(addTrackers(trackersData))
                    setLoading(false)
                })
            } else {
                dispatch(resetTrackers())
            }
        }

        fetchTrackers()
    }, [user])

    if (loading) {
        return <Spinner />
    }

    return (
        <>
            <Router>
                <Route exact path='/' element={<Login />} />
                <Route
                    exact
                    path={PUBLIC_PATHS.forgotPassword}
                    element={<ForgotPassword />}
                />

                <Route path='/register' element={<Register />} />
                <Route path={FARMER_PATHS.dashboard} element={<PrivateRoute />}>
                    <Route
                        path={FARMER_PATHS.dashboard}
                        element={<FarmerDashboard />}
                    />
                </Route>
                <Route path={FARMER_PATHS.profile} element={<PrivateRoute />}>
                    <Route
                        path={FARMER_PATHS.profile}
                        element={<FarmerProfile />}
                    />
                </Route>
                <Route path={FARMER_PATHS.trackers} element={<PrivateRoute />}>
                    <Route
                        path={FARMER_PATHS.trackers}
                        element={<FarmerTrackers />}
                    />
                </Route>
                <Route
                    path={FARMER_PATHS.dailyTransactions}
                    element={<PrivateRoute />}
                >
                    <Route
                        path={FARMER_PATHS.dailyTransactions}
                        element={<FarmerDailyTransactions />}
                    />
                </Route>
                <Route
                    path={`${FARMER_PATHS.annualProfitAndLoss}/:year`}
                    element={<PrivateRoute />}
                >
                    <Route
                        path={`${FARMER_PATHS.annualProfitAndLoss}/:year`}
                        element={<FarmerAnnualProfitAndLossReport />}
                    />
                </Route>
                <Route
                    path={FARMER_PATHS.loanCalculator}
                    element={<PrivateRoute />}
                >
                    <Route
                        path={FARMER_PATHS.loanCalculator}
                        element={<FarmerLoanCalculator />}
                    />
                </Route>
                <Route
                    path={`${FARMER_PATHS.trackerEntries}:trackerName/:id`}
                    element={<PrivateRoute />}
                >
                    <Route
                        path={`${FARMER_PATHS.trackerEntries}:trackerName/:id`}
                        element={<FarmerTrackerEntries />}
                    />
                </Route>
                <Route path='/*' element={<ErrorPage />} />
            </Router>

            <ToastContainer />
        </>
    )
}

export default Routes
