import { fCurrency } from 'utils'

const IncomeEntryCell = ({
    trackerId,
    month,
    trackerEntries,
    filteredTrackers
}) => {
    function getEntryCellAmount() {
        let trackerIds = filteredTrackers.map((tracker, index) => tracker.id)

        let expenseEntries = trackerEntries.filter(
            (entry) =>
                entry.data.type == 'Income' &&
                entry.data.month == month &&
                entry.data.actual == false &&
                entry.data.trackerId == trackerId
        )

        return expenseEntries?.reduce((previous, current) => {
            return previous + current.data.amount
        }, 0)
    }
    return <td>{fCurrency(getEntryCellAmount())}</td>
}

export default IncomeEntryCell
